import React, { useEffect, useState } from "react";
import { Box, Button, Stack, TextField, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { BASE_URL } from "../../../services/baseURL";
import NftModal from "./NftModal";

const statusColors = {
  "awaiting publication": "#FFC107", // Yellow
  published: "#4CAF50", // Green
};

const isEnabledColors = {
  true: "#4CAF50", // Green
  false: "#F44336", // Red
};

export default function AllNft() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const placeholderAvatar = "https://via.placeholder.com/150?text=No+Avatar";

  const handleOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleSwitchChange = async (e) => {
    const newValue = e.target.checked;

    try {
      const token = localStorage.getItem("token");

      await axios.patch(
        `${BASE_URL}/api/visibility/collection_item/${selectedRow.id}`,
        { isEnabled: newValue },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSelectedRow((prev) => ({ ...prev, isEnabled: newValue }));

      setData((prevData) =>
        prevData.map((item) =>
          item.id === selectedRow.id ? { ...item, isEnabled: newValue } : item
        )
      );
      setFilteredData((prevData) =>
        prevData.map((item) =>
          item.id === selectedRow.id ? { ...item, isEnabled: newValue } : item
        )
      );
    } catch (error) {
      console.error("Error updating isEnabled:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resp = await axios.get(`${BASE_URL}/api/collection_items`);
        const processedData = resp.data.data.map((item) => ({
          ...item,
          id: item._id, // Используем _id как id для DataGrid
        }));
        setData(processedData);
        setFilteredData(processedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFilter = (status, isEnabled = null) => {
    setActiveFilter(status);
    const filtered =
      status === "all" ? data : data.filter((item) => item.status === status);

    const enabledFiltered =
      isEnabled === null
        ? filtered
        : filtered.filter((item) => item.isEnabled === isEnabled);

    const searchedData = enabledFiltered.filter(
      (item) =>
        item._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredData(searchedData);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered =
      activeFilter === "all"
        ? data
        : data.filter((item) => item.status === activeFilter);

    const searchedData = filtered.filter(
      (item) =>
        item._id.toLowerCase().includes(query.toLowerCase()) ||
        item.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(searchedData);
  };

  const columns = [
    { field: "_id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "categoryTitle", headerName: "Category", width: 150 },
    { field: "likes", headerName: "Likes", type: "number", width: 100 },
    { field: "level", headerName: "Level", type: "number", width: 100 },
    { field: "price", headerName: "Price Tribe", type: "number", width: 100 },
    { field: "tonPrice", headerName: "Price TON", type: "number", width: 100 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color: "white",
            backgroundColor: statusColors[params.row.status] || "#757575", // Default Gray
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          {params.row.status || "Unknown"}
        </span>
      ),
    },
    {
      field: "isEnabled",
      headerName: "Is Enabled",
      width: 100,
      renderCell: (params) => (
        <span
          style={{
            color: "white",
            backgroundColor: isEnabledColors[params.row.isEnabled] || "#757575", // Default Gray
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          {params.row.isEnabled ? "Yes" : "No"}
        </span>
      ),
    },
    {
      field: "image",
      headerName: "Image",
      width: 150,
      renderCell: (params) =>
        params.row?.image?.previewUrl ? (
          <img
            src={params.row.image.previewUrl}
            alt={params.row.name}
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => handleOpen(params.row)}
          />
        ) : (
          "No Image"
        ),
    },
  ];

  return (
    <div>
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <Button
          variant={activeFilter === "all" ? "contained" : "outlined"}
          onClick={() => handleFilter("all")}
        >
          ALL
        </Button>
        <Button
          variant={
            activeFilter === "awaiting publication" ? "contained" : "outlined"
          }
          onClick={() => handleFilter("awaiting publication")}
        >
          Awaiting Published
        </Button>
        <Button
          variant={activeFilter === "published" ? "contained" : "outlined"}
          onClick={() => handleFilter("published")}
        >
          Published
        </Button>
        <Button
          variant={activeFilter === "isEnabled" ? "contained" : "outlined"}
          onClick={() => handleFilter("all", true)}
        >
          Is Enabled
        </Button>
        <Button
          variant={activeFilter === "notEnabled" ? "contained" : "outlined"}
          onClick={() => handleFilter("all", false)}
        >
          Not Enabled
        </Button>
      </Stack>
      <TextField
        label="Search by ID or Name"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
        onChange={handleSearch}
        value={searchQuery}
      />
      {loading ? (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 400 }}>
          <CircularProgress />
        </Stack>
      ) : (
        <Box sx={{ height: 1160, padding: 0, width: "100%", margin: 0 }}>
          <DataGrid
            rows={filteredData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[10, 20, 50]}
          />
        </Box>
      )}
      <NftModal
        open={open}
        handleClose={handleClose}
        selectedRow={selectedRow}
        handleSwitchChange={handleSwitchChange}
        placeholderAvatar={placeholderAvatar}
      />
    </div>
  );
}
