import axios from "axios";
import { BASE_URL } from "./baseURL";

const getHeaders = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
};

export const getCategoryByTitle = async (title) => {
  const response = await axios.get(`${BASE_URL}/api/category/${title}`, {
    headers: getHeaders(),
  });
  console.log(response);
  return response.data;
};

export const updateCategory = async (id, body) => {
  console.log(id, body);
  const response = await axios.put(
    `${BASE_URL}/api/category/update/${id}`,
    body,
    { headers: getHeaders() }
  );
  return response.data;
};
