import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack,
  Typography,
} from "@mui/material";

const ExportModal = ({ open, onClose, onExport, selectedCount }) => {
  const [amount, setAmount] = React.useState("");
  const [comment, setComment] = React.useState("");

  const handleExport = () => {
    if (amount.trim() === "") {
      return;
    }
    onExport(amount, comment);
    setAmount("");
    setComment("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Export Users Data</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Typography variant="body1" color="text.secondary">
            Selected users: {selectedCount}
          </Typography>
          <TextField
            label="Amount"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
          <TextField
            label="Optional Comment"
            fullWidth
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            multiline
            rows={2}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleExport}
          variant="contained"
          disabled={!amount.trim()}
        >
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportModal;
