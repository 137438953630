import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../services/baseURL";

const UserBoost = () => {
  const [boostData, setBoostData] = useState([]);
  const [statusMap, setStatusMap] = useState({});

  const fetchBoostData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/boost-price`);
      setBoostData(response.data);
    } catch (error) {
      console.error("Error fetching boost data:", error);
    }
  };

  useEffect(() => {
    fetchBoostData();
  }, []);

  const handleInputChange = (event, id, field) => {
    const updatedData = boostData.map((item) => {
      if (item._id === id) {
        return { ...item, [field]: event.target.value };
      }
      return item;
    });
    setBoostData(updatedData);
  };

  const updateBoostData = async (itemId) => {
    const item = boostData.find((item) => item._id === itemId);
    if (!item) return;
    try {
      await axios.patch(`${BASE_URL}/boost-price/${itemId}`, {
        userLevel: item.userLevel,
        price: item.price,
        countSteps: item.countSteps,
      });
      updateStatus(itemId, "success");
    } catch (error) {
      console.error("Error updating boost data:", error);
      updateStatus(itemId, "error");
    }
  };

  const updateStatus = (itemId, status) => {
    setStatusMap((prevStatus) => ({ ...prevStatus, [itemId]: status }));
    setTimeout(() => {
      setStatusMap((prevStatus) => {
        const { [itemId]: _, ...rest } = prevStatus;
        return rest;
      });
    }, 3000);
  };

  return (
    <Box sx={{ p: 4, backgroundColor: "background.default", borderRadius: 2 }}>
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", color: "text.primary", mb: 2 }}
      >
        Boost Management
      </Typography>
      <Typography variant="body1" sx={{ color: "text.secondary", mb: 4 }}>
        This component allows you to configure user boost prices at each level
        and set the number of steps required to move to a new level.
      </Typography>
      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                User Level
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Price
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Count Steps
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {boostData.map((item) => {
              const itemStatus = statusMap[item._id] || "default";

              return (
                <TableRow key={item._id}>
                  <TableCell align="center">
                    <TextField
                      value={item.userLevel}
                      onChange={(e) =>
                        handleInputChange(e, item._id, "userLevel")
                      }
                      size="small"
                      type="number"
                      fullWidth
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      value={item.price}
                      onChange={(e) => handleInputChange(e, item._id, "price")}
                      size="small"
                      type="number"
                      fullWidth
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      value={item.countSteps}
                      onChange={(e) =>
                        handleInputChange(e, item._id, "countSteps")
                      }
                      size="small"
                      type="number"
                      fullWidth
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color={itemStatus === "success" ? "success" : "primary"}
                      onClick={() => updateBoostData(item._id)}
                      disabled={itemStatus === "success"}
                      sx={{ width: "100px" }}
                    >
                      {itemStatus === "success" ? "Saved" : "Save"}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserBoost;
