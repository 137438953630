import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Switch,
  FormControlLabel,
  Button,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxWidth: "800px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  overflowY: "auto",
};

const imageModalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  bgcolor: "rgba(0, 0, 0, 0.6)", // Мягкое затемнение фона
  zIndex: 1300,
};

const CollectionModal = ({
  open,
  onClose,
  selectedRow,
  handleToggleChange,
}) => {
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const placeholderAvatar = "https://via.placeholder.com/150?text=No+Avatar";

  const handleImageClick = (imageUrl) => {
    setCurrentImage(imageUrl);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
    setCurrentImage(null);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            {selectedRow && (
              <>
                {/* Header Section */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      selectedRow.user?.profile?.photo?.previewUrl ||
                      placeholderAvatar
                    }
                    alt="User Avatar"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      marginRight: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleImageClick(
                        selectedRow.user?.profile?.photo?.previewUrl ||
                          placeholderAvatar
                      )
                    }
                  />
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold" }}
                      color="text.primary"
                    >
                      {selectedRow.name || "No Name"}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      User Type:{" "}
                      <strong>{selectedRow.user?.type || "N/A"}</strong>
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 3 }} />

                {/* Content Section */}
                <Grid container spacing={2}>
                  {/* Left Section: Images */}
                  <Grid item xs={12} md={6}>
                    <Box sx={{ textAlign: "center", mb: 2 }}>
                      <Typography variant="caption" color="text.primary">
                        Collection Avatar
                      </Typography>
                      <img
                        src={
                          selectedRow.avatar?.previewUrl ||
                          "https://via.placeholder.com/150"
                        }
                        alt="Collection Avatar"
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "cover",
                          borderRadius: "8px",
                          marginBottom: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleImageClick(
                            selectedRow.avatar?.previewUrl ||
                              "https://via.placeholder.com/150"
                          )
                        }
                      />
                    </Box>
                  </Grid>

                  {/* Right Section: Information */}
                  <Grid item xs={12} md={6}>
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                        color="text.primary"
                      >
                        {selectedRow.name || "No Name"}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1 }}
                      color="text.primary"
                    >
                      <strong>Category:</strong>{" "}
                      {selectedRow.categoryTitle || "N/A"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1 }}
                      color="text.primary"
                    >
                      <strong>Status:</strong> {selectedRow.status || "Unknown"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1 }}
                      color="text.primary"
                    >
                      <strong>Level:</strong> {selectedRow.level || "N/A"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1 }}
                      color="text.primary"
                    >
                      <strong>Likes:</strong> {selectedRow.likes || "N/A"}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={selectedRow.isEnabled}
                          onChange={handleToggleChange}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "bold" }}
                          color={
                            selectedRow.isEnabled
                              ? "success.main"
                              : "error.main"
                          }
                        >
                          {selectedRow.isEnabled ? "Enabled" : "Disabled"}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="caption" color="text.primary">
                    Collection Banner
                  </Typography>
                  <img
                    src={
                      selectedRow.banner?.previewUrl ||
                      "https://via.placeholder.com/300x100"
                    }
                    alt="Collection Banner"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleImageClick(
                        selectedRow.banner?.previewUrl ||
                          "https://via.placeholder.com/300x100"
                      )
                    }
                  />
                </Box>
                <Divider sx={{ my: 3 }} />

                {/* Footer Section */}
                <Typography variant="body2" sx={{ mb: 2 }} color="text.primary">
                  <strong>Description:</strong>{" "}
                  {selectedRow.description || "No description available."}
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={onClose}
                  sx={{
                    mt: 3,
                    bgcolor: "primary.main",
                    ":hover": {
                      bgcolor: "primary.dark",
                    },
                  }}
                >
                  Close
                </Button>
              </>
            )}
          </Box>
        </Fade>
      </Modal>

      <Modal open={imageModalOpen} onClose={handleImageModalClose}>
        <Box sx={imageModalStyle} onClick={handleImageModalClose}>
          <img
            src={currentImage}
            alt="Enlarged View"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default CollectionModal;
