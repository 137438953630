import React from "react";
import { Box, Grid } from "@mui/material";
import UsersWidget from "../../Components/Dashboard/UsersWidget";
import ModerationCollectionsWidget from "../../Components/Dashboard/ModerationCollectionsWidget";
import ModerationNFTsWidget from "../../Components/Dashboard/ModerationNFTsWidget";
import CollectionsWidget from "../../Components/Dashboard/CollectionsWidget";
import AllNftWidget from "../../Components/Dashboard/AllNftWidget";
import SeasonsWidget from "../../Components/Dashboard/SeasonsWidget";

const Home = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Users Widget */}
        <Grid item xs={12}>
          <UsersWidget />
        </Grid>
        <Grid item xs={12}>
          <CollectionsWidget />
        </Grid>
        <Grid item xs={12}>
          <AllNftWidget />
        </Grid>
        <Grid item xs={12}>
          <SeasonsWidget />
        </Grid>

        {/* Collections and NFTs Widgets side by side */}
        <Grid item xs={10} md={6}>
          <ModerationCollectionsWidget />
        </Grid>
        <Grid item xs={10} md={6}>
          <ModerationNFTsWidget />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
