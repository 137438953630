import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import { format } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditRewardModal from "../../Components/EditRewardModal/EditRewardModal";
import AddRewardModal from "../../Components/AddRewardModal/AddRewardModal";
import { BASE_URL } from "../../services/baseURL";
import ActiveSeasonInfo from "./ActiveSeasonInfo";

function Rewards() {
  const [rewardsData, setRewardsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${BASE_URL}/rewards_season/all_seasons`);
        const data = await response.json();
        console.log(data);
        setRewardsData(data.map((reward) => ({ ...reward, id: reward._id }))); // Add id field for DataGrid
      } catch (error) {
        console.error("Error fetching rewards data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleEditClick = (reward) => {
    setSelectedReward(reward);
    setOpenEditModal(true);
  };

  const handleAddClick = () => {
    setOpenAddModal(true);
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
    setSelectedReward(null);
  };

  const handleAddModalClose = () => {
    setOpenAddModal(false);
  };

  const handleSaveChanges = () => {
    console.log("Updated Reward Data:", selectedReward);
    handleEditModalClose();
  };

  const handleSaveNewReward = async (newReward) => {
    try {
      const response = await fetch(`${BASE_URL}/rewards_season`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newReward),
      });

      if (response.ok) {
        const savedReward = await response.json();
        setRewardsData((prevRewards) => [
          ...prevRewards,
          { ...savedReward, id: savedReward._id },
        ]);
        handleAddModalClose();
      } else {
        console.error("Failed to add new reward:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding new reward:", error);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        color: "text.primary",
        padding: 4,
        borderRadius: 2,
      }}
    >
      <Typography variant="h4" fontWeight="bold" sx={{ mb: 2 }}>
        Manage Rewards Seasons
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <ActiveSeasonInfo rewardsData={rewardsData} />
      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddClick}
        >
          Add New
        </Button>
      </Stack>

      {loading ? (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 400 }}>
          <CircularProgress />
        </Stack>
      ) : (
        <Box sx={{ height: 600 }}>
          <DataGrid
            rows={rewardsData.map((reward) => ({
              ...reward,
              id: reward._id, // ID для DataGrid
              nftCount: reward.nfts?.length || 0, // Безопасный подсчет
            }))}
            columns={[
              { field: "name", headerName: "Name", width: 200 },
              { field: "description", headerName: "Description", width: 100 },
              {
                field: "acceptNftLevel",
                headerName: "NFT Level",
                width: 100,
              },
              {
                field: "daysCount",
                headerName: "Days ",
                type: "number",
                width: 50,
              },
              {
                field: "startDate",
                headerName: "Start Date",
                width: 150,
                renderCell: ({ value }) => {
                  return format(new Date(value), "dd-MM-yyyy") || "N/A";
                },
              },
              {
                field: "endDate",
                headerName: "End Date",
                width: 150,

                renderCell: ({ value }) => {
                  return format(new Date(value), "dd-MM-yyyy") || "N/A";
                },
              },
              {
                field: "isActive",
                headerName: "Active",
                width: 100,
                renderCell: ({ value }) => (
                  <span
                    style={{
                      color: value ? "green" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {value ? "Active" : "No"}
                  </span>
                ),
              },
              {
                field: "nftCount",
                headerName: "NFTs in Season",
                width: 150,
              },
              {
                field: "actions",
                headerName: "Actions",
                width: 150,
                renderCell: (params) => (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleEditClick(params.row)}
                  >
                    Edit
                  </Button>
                ),
              },
            ]}
            pageSizeOptions={[5, 10, 20]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </Box>
      )}

      <EditRewardModal
        open={openEditModal}
        reward={selectedReward}
        onClose={handleEditModalClose}
        onSave={handleSaveChanges}
      />
      <AddRewardModal
        open={openAddModal}
        onClose={handleAddModalClose}
        onSave={handleSaveNewReward}
      />
    </Box>
  );
}

export default Rewards;
