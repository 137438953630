import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  TonConnectButton,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import { Address, beginCell, toNano } from "@ton/ton";
import axios from "axios";
import { BASE_URL } from "../../services/baseURL";
import EditPriceModal from "./EditPriceModal";

const ChangePriceLike = () => {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const [type, setType] = useState("CollectionItem");
  const [existingData, setExistingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [burnInputValue, setBurnInputValue] = useState("");
  const [jettonWalletData, setJettonWalletData] = useState([]);
  const [likeBurns, setLikeBurns] = useState("");
  const [totalLikes, setTotalLikes] = useState(0);
  const accessToken = localStorage.getItem("token");
  const jettonAddress = "EQCcXKwYGCCZnzQj9vwwg8Y-F3d8H7cow-Mwgj8pTFruBfP8";

  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns = [
    {
      field: "itemLevel",
      headerName: "Level",
      width: 80,
      type: "number",
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
      type: "number",
    },
    {
      field: "ownerFee",
      headerName: "Owner Fee",
      width: 100,
      type: "number",
    },
    {
      field: "bern",
      headerName: "Burn",
      width: 100,
      type: "number",
    },
    {
      field: "fund",
      headerName: "Fund",
      width: 100,
      type: "number",
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 100,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEdit(params.row)}
          size="small"
        >
          Edit
        </Button>
      ),
    },
  ];

  const handleEdit = (row) => {
    setSelectedItem(row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (wallet?.account?.address) {
      fetchJettonWallets(wallet.account.address);
    }
  }, [wallet]);

  const fetchData = async () => {
    setLoading(true);
    try {
      // Запрос цен лайков
      const pricesResponse = await axios.get(
        `${BASE_URL}/api/admin/like-prices`
      );

      if (
        pricesResponse?.data?.data &&
        Array.isArray(pricesResponse.data.data)
      ) {
        const processedData = pricesResponse.data.data.map((item) => {
          const price = Number(item.price) || 0;
          const ownerFee = Number(item.ownerFee) || 0;
          const bern = Number(item.bern) || 0;
          const fund = price - (ownerFee + bern);

          return {
            ...item,
            id: item._id,
            price,
            ownerFee,
            bern,
            fund,
            itemLevel: Number(item.itemLevel) || 0,
            userLevel: Number(item.userLevel) || 0,
          };
        });
        setExistingData(processedData);
      }

      // Запрос данных фонда
      const fondResponse = await axios.get(`${BASE_URL}/likes_fond`);

      if (fondResponse?.data && fondResponse.data[0]) {
        setLikeBurns(fondResponse.data[0].burnCommission);
        setTotalLikes(fondResponse.data[0].totalLikes);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchJettonWallets = async (ownerAddress) => {
    try {
      const response = await axios.get(
        `https://toncenter.com/api/v3/jetton/wallets?owner_address=${ownerAddress}&jetton_address=${jettonAddress}&limit=128&offset=0`
      );
      const wallets = response.data.jetton_wallets.map((wallet) => ({
        address: wallet.address,
        balance: parseFloat(wallet.balance) / 1e2,
      }));
      setJettonWalletData(wallets);
    } catch (error) {
      console.error("Error fetching jetton wallets:", error);
    }
  };

  const handleBurnChange = (event) => {
    const input = event.target.value;
    if (input === "") {
      setBurnInputValue("");
      return;
    }

    const parsedValue = parseFloat(input);
    const maxValue = parseFloat(likeBurns);

    if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= maxValue) {
      setBurnInputValue(input);
    } else if (parsedValue < 0) {
      setBurnInputValue("");
    } else if (parsedValue > maxValue) {
      setBurnInputValue(maxValue.toString());
    }
  };

  const updateTransaction = (wallets, callback) => {
    if (wallets.length > 0) {
      const inputAmountNano = burnInputValue * 100;
      const firstWallet = wallets[0];

      const body = beginCell()
        .storeUint(0x595f07bc, 32)
        .storeUint(0, 64)
        .storeCoins(inputAmountNano)
        .storeAddress(Address.parse(firstWallet.address))
        .storeUint(0, 1)
        .endCell();

      const newTransaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: firstWallet.address,
            amount: toNano(0.05).toString(),
            payload: body.toBoc().toString("base64"),
          },
        ],
      };

      callback(newTransaction);
    }
  };

  const sendBurnTransaction = async (transaction) => {
    try {
      const response = await tonConnectUI.sendTransaction(transaction);
      await axios.put(
        `${BASE_URL}/likes_fond/decrease-commission`,
        { amount: Number(burnInputValue) },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setLikeBurns(likeBurns);
      setBurnInputValue("");
      console.log("Burn transaction confirmed!");
      await fetchData(); // Обновляем все данные после транзакции
    } catch (error) {
      console.error("Transaction error:", error);
    }
  };

  const handleBurnSubmit = () => {
    updateTransaction(jettonWalletData, sendBurnTransaction);
  };

  const handleSave = async (params) => {
    if (!params.row) {
      console.error("No row data available");
      return;
    }

    try {
      const data = {
        itemType: params.row.itemType,
        price: Number(params.row.price) || 0,
        ownerFee: Number(params.row.ownerFee) || 0,
        bern: Number(params.row.bern) || 0,
        userLevel: params.row.userLevel || 0,
        itemLevel: params.row.itemLevel || 0,
      };

      await axios.patch(
        `${BASE_URL}/api/admin/like-prices/${params.row._id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      await fetchData(); // Обновляем данные после сохранения
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
        <TonConnectButton />
      </Stack>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Burn Settings
          </Typography>
          <Stack spacing={2}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <TextField
                label="Burn Input"
                variant="outlined"
                value={burnInputValue}
                onChange={handleBurnChange}
                size="small"
              />
              <TextField
                label="Current Burn"
                variant="outlined"
                value={likeBurns}
                InputProps={{ readOnly: true }}
                size="small"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleBurnSubmit}
                sx={{ height: 40 }}
              >
                Set Burn
              </Button>
            </Box>
            <Typography variant="body2" color="text.secondary">
              Total Likes: {totalLikes}
            </Typography>
          </Stack>
        </CardContent>
      </Card>

      <Paper sx={{ mb: 4, p: 2 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Select type</InputLabel>
            <Select
              value={type}
              label="Select type"
              onChange={(e) => setType(e.target.value)}
              size="small"
            >
              <MenuItem value="NFTCollection">NFT Collection</MenuItem>
              <MenuItem value="CollectionItem">Collection item</MenuItem>
            </Select>
          </FormControl>
          <Typography color="text.secondary">
            {`${
              existingData.filter((item) => item.itemType === type).length
            } items`}
          </Typography>
        </Stack>
      </Paper>

      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={existingData.filter((item) => item.itemType === type)}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 12,
              },
            },
          }}
          pageSizeOptions={[5, 12, 20]}
          density="compact"
          loading={loading}
          slots={{
            noRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <Typography color="text.secondary">
                  No data available for selected type
                </Typography>
              </Stack>
            ),
          }}
        />
      </Box>
      {isModalOpen && (
        <EditPriceModal
          open={isModalOpen}
          onClose={handleCloseModal}
          data={selectedItem}
          onSave={handleSave}
        />
      )}
    </Box>
  );
};

export default ChangePriceLike;
