import React from "react";
import { Box, Paper, Typography, Grid, Chip, Stack } from "@mui/material";
import { format, differenceInDays } from "date-fns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AssignmentIcon from "@mui/icons-material/Assignment";
import StarIcon from "@mui/icons-material/Star";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import SpeedIcon from "@mui/icons-material/Speed";

const ActiveSeasonInfo = ({ rewardsData }) => {
  const activeSeasons = rewardsData.filter((season) => season.isActive);

  if (activeSeasons.length === 0) {
    return (
      <Paper
        elevation={3}
        sx={{ p: 3, mb: 4, backgroundColor: "background.paper" }}
      >
        <Typography variant="h6" color="text.secondary" align="center">
          No active seasons at the moment
        </Typography>
      </Paper>
    );
  }

  return (
    <>
      {activeSeasons.map((season) => {
        const daysLeft = differenceInDays(new Date(season.endDate), new Date());

        return (
          <Paper
            key={season._id}
            elevation={3}
            sx={{
              p: 3,
              mb: 4,
              backgroundColor: "background.paper",
              borderTop: "4px solid #1976d2",
            }}
          >
            <Box sx={{ mb: 2 }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <EmojiEventsIcon color="primary" />
                <Typography variant="h5" fontWeight="bold">
                  {season.name}
                </Typography>
                <Chip
                  label="Active"
                  color="success"
                  size="small"
                  sx={{ ml: 2 }}
                />
              </Stack>
              <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                {season.description}
              </Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      <CalendarTodayIcon
                        sx={{
                          fontSize: "small",
                          mr: 1,
                          verticalAlign: "middle",
                        }}
                      />
                      Season Period
                    </Typography>
                    <Typography variant="body1">
                      {format(new Date(season.startDate), "dd MMM yyyy")} -{" "}
                      {format(new Date(season.endDate), "dd MMM yyyy")}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      <AssignmentIcon
                        sx={{
                          fontSize: "small",
                          mr: 1,
                          verticalAlign: "middle",
                        }}
                      />
                      Duration & Status
                    </Typography>
                    <Typography variant="body1">
                      {season.daysCount} days total • {daysLeft} days remaining
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      <StarIcon
                        sx={{
                          fontSize: "small",
                          mr: 1,
                          verticalAlign: "middle",
                        }}
                      />
                      Minimum NFT Level Required
                    </Typography>
                    <Typography variant="body1">
                      Level {season.acceptNftLevel}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ bgcolor: "action.hover", p: 2, borderRadius: 1 }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="medium"
                    sx={{ mb: 2 }}
                  >
                    Season Coefficients
                  </Typography>
                  <Stack spacing={1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        <StarIcon
                          sx={{
                            fontSize: "small",
                            mr: 1,
                            verticalAlign: "middle",
                          }}
                        />
                        NFT Level
                      </Typography>
                      <Typography variant="body1">
                        {season.coefficients.nftLevel}x
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        <ThumbUpIcon
                          sx={{
                            fontSize: "small",
                            mr: 1,
                            verticalAlign: "middle",
                          }}
                        />
                        NFT Likes
                      </Typography>
                      <Typography variant="body1">
                        {season.coefficients.nftLikesCount}x
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        <SpeedIcon
                          sx={{
                            fontSize: "small",
                            mr: 1,
                            verticalAlign: "middle",
                          }}
                        />
                        User Boosts
                      </Typography>
                      <Typography variant="body1">
                        {season.coefficients.userBoostsCount}x
                      </Typography>
                    </Box>
                  </Stack>
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ mb: 1 }}
                  >
                    Participating NFTs
                  </Typography>
                  <Typography variant="h6">
                    {season.nfts?.length || 0} NFTs
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </>
  );
};

export default ActiveSeasonInfo;
