import React, { useEffect, useState } from "react";
import { Box, Button, Stack, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { BASE_URL } from "../../../services/baseURL";
import CollectionModal from "./CollectionModal";

const statusColors = {
  published: "#4CAF50", // Green
  "awaiting publication": "#FFC107", // Yellow
};

const isEnabledColors = {
  true: "#4CAF50", // Green
  false: "#F44336", // Red
};

const columns = [
  { field: "_id", headerName: "ID", width: 100 },
  { field: "name", headerName: "Name", width: 150 },
  { field: "description", headerName: "Description", width: 200 },
  { field: "categoryTitle", headerName: "Category", width: 150 },
  { field: "likes", headerName: "Likes", type: "number", width: 100 },
  { field: "level", headerName: "Level", type: "number", width: 100 },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    renderCell: (params) => (
      <span
        style={{
          color: "white",
          backgroundColor: statusColors[params.row.status] || "#757575", // Default Gray
          padding: "4px 8px",
          borderRadius: "4px",
        }}
      >
        {params.row.status || "Unknown"}
      </span>
    ),
  },
  {
    field: "isEnabled",
    headerName: "Is Enabled",
    width: 150,
    renderCell: (params) => (
      <span
        style={{
          color: "white",
          backgroundColor: isEnabledColors[params.row.isEnabled] || "#757575", // Default Gray
          padding: "4px 8px",
          borderRadius: "4px",
        }}
      >
        {params.row.isEnabled ? "Yes" : "No"}
      </span>
    ),
  },
  {
    field: "image",
    headerName: "Image",
    width: 150,
    renderCell: (params) =>
      params.row?.avatar?.previewUrl ? (
        <img
          src={params.row.avatar.previewUrl}
          alt={params.row.name || "No Image"}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "4px",
          }}
        />
      ) : (
        "No Image"
      ),
  },
];

const Collections = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/collections`);
        const processedData = response.data.data.map((item) => ({
          ...item,
          id: item._id, // Используем _id как id для DataGrid
        }));
        setData(processedData);
        setFilteredData(processedData);
        console.log(response);
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };

    fetchData();
  }, []);

  const handleFilter = (status, isEnabled = null) => {
    setActiveFilter(status);
    const filtered =
      status === "all" ? data : data.filter((item) => item.status === status);

    const enabledFiltered =
      isEnabled === null
        ? filtered
        : filtered.filter((item) => item.isEnabled === isEnabled);

    const searchedData = enabledFiltered.filter(
      (item) =>
        item._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredData(searchedData);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered =
      activeFilter === "all"
        ? data
        : data.filter((item) => item.status === activeFilter);

    const searchedData = filtered.filter(
      (item) =>
        item._id.toLowerCase().includes(query.toLowerCase()) ||
        item.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(searchedData);
  };

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };

  const handleToggleChange = async (newValue) => {
    if (selectedRow) {
      try {
        const token = localStorage.getItem("token");
        await axios.patch(
          `${BASE_URL}/api/visibility/collection/${selectedRow.id}`,
          { isEnabled: newValue },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Обновляем локально
        setSelectedRow((prev) => ({ ...prev, isEnabled: newValue }));
        setData((prevData) =>
          prevData.map((item) =>
            item.id === selectedRow.id ? { ...item, isEnabled: newValue } : item
          )
        );
        setFilteredData((prevData) =>
          prevData.map((item) =>
            item.id === selectedRow.id ? { ...item, isEnabled: newValue } : item
          )
        );
      } catch (error) {
        console.error("Error updating isEnabled:", error);
      }
    }
  };

  return (
    <div>
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <Button
          variant={activeFilter === "all" ? "contained" : "outlined"}
          onClick={() => handleFilter("all")}
        >
          ALL
        </Button>
        <Button
          variant={
            activeFilter === "awaiting publication" ? "contained" : "outlined"
          }
          onClick={() => handleFilter("awaiting publication")}
        >
          Awaiting Publication
        </Button>
        <Button
          variant={activeFilter === "published" ? "contained" : "outlined"}
          onClick={() => handleFilter("published")}
        >
          Published
        </Button>
      </Stack>
      {/* Поиск */}
      <TextField
        label="Search by ID or Name"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
        onChange={handleSearch}
        value={searchQuery}
      />
      {/* Таблица */}
      <Box sx={{ height: 1160, padding: 0, width: "100%", margin: 0 }}>
        <DataGrid
          rows={filteredData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[10, 20, 50]}
          onRowClick={handleRowClick}
        />
      </Box>

      {/* Модальное окно */}
      <CollectionModal
        open={isModalOpen}
        onClose={handleCloseModal}
        selectedRow={selectedRow}
        handleToggleChange={(e) => handleToggleChange(e.target.checked)}
      />
    </div>
  );
};

export default Collections;
