import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Typography,
  Box,
} from "@mui/material";

const EditPriceModal = ({ open, onClose, data, onSave }) => {
  const [formData, setFormData] = useState({
    price: 0,
    ownerFee: 0,
    bern: 0,
  });

  useEffect(() => {
    if (data) {
      setFormData({
        price: data.price || 0,
        ownerFee: data.ownerFee || 0,
        bern: data.bern || 0,
      });
    }
  }, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const numValue = Number(value) || 0;
    setFormData((prev) => ({
      ...prev,
      [name]: numValue,
    }));
  };

  const calculatedFund = formData.price - (formData.ownerFee + formData.bern);

  const handleSave = () => {
    onSave({
      ...data,
      ...formData,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Price Settings</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <TextField
            label="Price"
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Owner Fee"
            type="number"
            name="ownerFee"
            value={formData.ownerFee}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Burn"
            type="number"
            name="bern"
            value={formData.bern}
            onChange={handleChange}
            fullWidth
          />
          <Typography variant="body1" sx={{ fontWeight: "medium" }}>
            Calculated Fund: {calculatedFund}
          </Typography>

          {data && (
            <Box sx={{ bgcolor: "action.hover", p: 2, borderRadius: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                Additional Information
              </Typography>
              <Typography variant="body2">Level: {data.itemLevel}</Typography>
              <Typography variant="body2">Type: {data.itemType}</Typography>
              <Typography variant="body2">Currency: {data.currency}</Typography>
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPriceModal;
