import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RefreshIcon from "@mui/icons-material/Refresh";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import UpdateIcon from "@mui/icons-material/Update";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { BASE_URL } from "../../services/baseURL";

export default function SeasonsWidget() {
  const [seasonsData, setSeasonsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchSeasons = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/rewards_season/all_seasons`);
      const data = await response.json();
      setSeasonsData(data);
      setError(null);
    } catch (err) {
      setError("Failed to load seasons data");
      console.error("Error fetching seasons:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSeasons();
  }, []);

  const getStatistics = () => {
    if (!seasonsData) return null;

    const activeSeasons = seasonsData.filter((season) => season.isActive);
    const totalNFTs = seasonsData.reduce(
      (sum, season) => sum + (season.nfts?.length || 0),
      0
    );
    const upcomingSeasons = seasonsData.filter(
      (season) => new Date(season.startDate) > new Date() && !season.isActive
    );

    // Find season with most NFTs
    const topNFTsSeason = seasonsData.reduce((prev, current) =>
      (prev.nfts?.length || 0) > (current.nfts?.length || 0) ? prev : current
    );

    return {
      total: seasonsData.length,
      active: activeSeasons.length,
      upcoming: upcomingSeasons.length,
      totalNFTs,
      nextSeason: upcomingSeasons.sort(
        (a, b) => new Date(a.startDate) - new Date(b.startDate)
      )[0],
      topNFTsSeason,
    };
  };

  const renderStat = (icon, label, value) => (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        p: 1.5,
        textAlign: "left",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        {icon}
        <Typography
          variant="h6"
          component="div"
          sx={{ ml: 1, fontSize: "1rem" }}
        >
          {label}
        </Typography>
      </Box>
      <Typography
        variant="h4"
        component="div"
        sx={{
          fontWeight: "bold",
        }}
      >
        {value}
      </Typography>
    </Grid>
  );

  const renderSeasonCard = (icon, title, season, attribute) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        p: 2,
        flex: 1,
        textAlign: "left",
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        {icon}
        <Typography
          variant="h6"
          sx={{ ml: 1, fontWeight: "bold", fontSize: "1rem" }}
        >
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          {season?.name || "N/A"}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Start Date:{" "}
          {season ? format(new Date(season.startDate), "dd MMM yyyy") : "N/A"}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Duration: {season?.daysCount || 0} days
        </Typography>
        <Typography variant="body2">
          {attribute}:{" "}
          {season
            ? (attribute === "nfts"
                ? season.nfts?.length
                : season[attribute]) || 0
            : 0}
        </Typography>
      </Box>
    </Box>
  );

  const stats = getStatistics();

  if (loading) {
    return (
      <Card
        sx={{
          borderRadius: 2,
          boxShadow: 3,
          width: "98%",
          minHeight: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Card>
    );
  }

  if (error) {
    return (
      <Card
        sx={{
          borderRadius: 2,
          boxShadow: 3,
          width: "98%",
          minHeight: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography color="error">{error}</Typography>
      </Card>
    );
  }

  return (
    <Card sx={{ borderRadius: 2, boxShadow: 3, width: "98%" }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h5">Rewards Seasons Statistics</Typography>
          <IconButton onClick={fetchSeasons} size="small">
            <RefreshIcon />
          </IconButton>
        </Box>

        <Grid container spacing={1} sx={{ mb: 2 }}>
          {renderStat(
            <EmojiEventsIcon fontSize="large" color="primary" />,
            "Total Seasons",
            stats.total
          )}
          {renderStat(
            <AutorenewIcon fontSize="large" color="secondary" />,
            "Active Seasons",
            stats.active
          )}
          {renderStat(
            <UpcomingIcon fontSize="large" color="success" />,
            "Upcoming Seasons",
            stats.upcoming
          )}
        </Grid>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={4}>
            {renderSeasonCard(
              <AccessTimeIcon color="primary" />,
              "Current Active Season",
              seasonsData.find((season) => season.isActive),
              "daysCount"
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {renderSeasonCard(
              <UpdateIcon color="secondary" />,
              "Next Upcoming Season",
              stats.nextSeason,
              "daysCount"
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {renderSeasonCard(
              <CalendarMonthIcon color="success" />,
              "Most Popular Season",
              stats.topNFTsSeason,
              "nfts"
            )}
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Season NFTs Distribution
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 1,
            p: 2,
            mb: 2,
          }}
        >
          {seasonsData.map((season) => (
            <Box key={season._id} sx={{ textAlign: "center", minWidth: 120 }}>
              <Typography variant="body1" fontWeight="bold">
                {season.name}
              </Typography>
              <Typography variant="body2">
                NFTs: {season.nfts?.length || 0}
              </Typography>
            </Box>
          ))}
        </Box>

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 1 }}
          fullWidth
          onClick={() => navigate("/manage-rewards")}
        >
          Go to Rewards Seasons Page
        </Button>
      </CardContent>
    </Card>
  );
}
