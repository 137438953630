import React, { createContext, useState, useMemo, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const ThemeContext = createContext();

export const ThemeProviderWrapper = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    // Проверка сохранённой темы в localStorage
    const savedTheme = localStorage.getItem("preferred-theme");
    if (savedTheme) {
      setDarkMode(savedTheme === "dark");
    } else {
      // Автоопределение системной темы
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setDarkMode(systemPrefersDark);
    }
  }, []);

  const theme = useMemo(() => {
    const darkPalette = {
      mode: "dark",
      background: {
        default: "#121212",
        paper: "#1E1E1E",
      },
      text: {
        primary: "#E0E0E0",
        secondary: "#B3B3B3",
      },
      primary: {
        main: "#BB86FC",
      },
      secondary: {
        main: "#03DAC6",
      },
      divider: "#373737",
    };

    const lightPalette = {
      mode: "light",
      background: {
        default: "#F5F5F5",
        paper: "#FFFFFF",
      },
      text: {
        primary: "#000000",
        secondary: "#4F4F4F",
      },
      primary: {
        main: "#1976d2",
      },
      secondary: {
        main: "#dc004e",
      },
      divider: "#E0E0E0",
    };

    return createTheme({
      palette: darkMode ? darkPalette : lightPalette,
      typography: {
        fontFamily: "'Roboto', 'Arial', sans-serif",
      },
    });
  }, [darkMode]);

  const toggleTheme = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem("preferred-theme", newMode ? "dark" : "light");
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
