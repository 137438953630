import React, { useState } from "react";

import "./gallery.scss";
import GalleryMain from "../../Components/GalleryMain/GalleryMain";
import GalleryDetails from "../../Components/GalleryDetails/GalleryDetails";

const Gallery = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemDoubleClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <div style={{ display: "flex" }}>
      <GalleryMain onItemDoubleClick={handleItemDoubleClick} />
      <GalleryDetails nft={selectedItem} />
    </div>
  );
};

export default Gallery;
