import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Modal,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../services/baseURL";
import CollectionsIcon from "@mui/icons-material/Collections";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CategoryIcon from "@mui/icons-material/Category";

export default function ModerationCollectionsWidget() {
  const [stats, setStats] = useState({
    totalCollections: 0,
    pendingCollections: 0,
    categories: [],
    latestCollection: null,
  });
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const axiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          localStorage.removeItem("token");
          navigate("/login");
        }
        return Promise.reject(error);
      }
    );

    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/api/moderation/collections");
        const data = response.data.data;

        const totalCollections = data.length;
        const pendingCollections = data.filter(
          (item) => item.status === "pending"
        ).length;
        const categories = Array.from(
          new Set(data.map((item) => item.categoryTitle))
        );
        const latestCollection = data[data.length - 1];

        setStats({
          totalCollections,
          pendingCollections,
          categories,
          latestCollection,
        });
      } catch (error) {
        console.error("Error fetching collections data:", error);
      }
    };

    fetchData();
  }, [navigate]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  const renderStat = (icon, label, value, isHighlighted = false) => (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        p: 2,
        wordBreak: "break-word",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {icon}
        <Typography
          variant="h6"
          sx={{
            ml: 1.5,
            fontSize: "clamp(14px, 2vw, 18px)", // Адаптивный шрифт
          }}
        >
          {label}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: isHighlighted
            ? "clamp(20px, 3vw, 32px)"
            : "clamp(14px, 2vw, 18px)", // Адаптивный шрифт
          fontWeight: isHighlighted ? "bold" : "normal",
        }}
      >
        {value}
      </Typography>
    </Grid>
  );

  return (
    <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Moderation Collections Statistics
        </Typography>

        <Grid container spacing={1}>
          {renderStat(
            <CollectionsIcon fontSize="large" color="primary" />,
            "Total Collections",
            stats.totalCollections,
            true
          )}
          {renderStat(
            <PendingActionsIcon fontSize="large" color="secondary" />,
            "Pending Collections",
            stats.pendingCollections,
            true
          )}
          {renderStat(
            <CategoryIcon fontSize="large" color="action" />,
            "Categories",
            stats.categories.length,
            true
          )}
        </Grid>

        {stats.latestCollection && (
          <Box
            sx={{
              mt: 3,
              p: 1,
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 1,
                fontSize: "clamp(14px, 2vw, 18px)", // Адаптивный шрифт
              }}
            >
              Latest Collection
            </Typography>
            <Typography variant="body1">
              <strong>{stats.latestCollection.name}</strong>
            </Typography>
            <Typography variant="body2">
              Category: {stats.latestCollection.categoryTitle}
            </Typography>
            <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
              <Box
                component="img"
                src={stats.latestCollection.avatar.previewUrl}
                alt="Avatar"
                sx={{
                  width: 80,
                  height: 80,
                  borderRadius: 2,
                  cursor: "pointer",
                  objectFit: "cover",
                  boxShadow: 1,
                }}
                onClick={() =>
                  handleImageClick(stats.latestCollection.avatar.fileUrl)
                }
              />
              <Box
                component="img"
                src={stats.latestCollection.banner.previewUrl}
                alt="Banner"
                sx={{
                  width: 120,
                  height: 80,
                  borderRadius: 2,
                  cursor: "pointer",
                  objectFit: "cover",
                  boxShadow: 1,
                }}
                onClick={() =>
                  handleImageClick(stats.latestCollection.banner.fileUrl)
                }
              />
            </Box>
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          fullWidth
          onClick={() => navigate("/moderation-new")}
        >
          Go to Moderation Page
        </Button>
      </CardContent>

      {/* Модальное окно для показа изображения */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%",
            maxHeight: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            p: 1,
          }}
        >
          <Box
            component="img"
            src={selectedImage}
            alt="Preview"
            sx={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
              borderRadius: 2,
            }}
          />
        </Box>
      </Modal>
    </Card>
  );
}
