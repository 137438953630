import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Box,
  Divider,
  Switch,
  FormControlLabel,
  Grid,
  useTheme,
} from "@mui/material";
import { BASE_URL } from "../../services/baseURL";

function AddRewardModal({ open, onClose, onSave }) {
  const theme = useTheme();
  const [newReward, setNewReward] = useState({
    name: "",
    description: "",
    acceptNftLevel: 0,
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
    isActive: false,
    coefficients: {
      nftLevel: 0,
      nftLikesCount: 0,
      userBoostsCount: 0,
    },
  });
  const [dayCount, setDayCount] = useState(0);

  useEffect(() => {
    if (newReward.startDate && newReward.endDate) {
      const start = new Date(newReward.startDate);
      const end = new Date(newReward.endDate);
      const diffInMs = end - start;
      const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      setDayCount(days);
    } else {
      setDayCount(0);
    }
  }, [newReward.startDate, newReward.endDate]);

  const handleFieldChange = (field, value) => {
    setNewReward((prev) => ({ ...prev, [field]: value }));
  };

  const handleNestedFieldChange = (field, nestedField, value) => {
    setNewReward((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [nestedField]: value,
      },
    }));
  };

  const handleSave = async () => {
    try {
      const formattedReward = {
        ...newReward,
        startDate: new Date(newReward.startDate).toISOString(),
        endDate: new Date(newReward.endDate).toISOString(),
      };

      const response = await fetch(`${BASE_URL}/rewards_season`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedReward),
      });

      if (response.ok) {
        onSave();
        onClose();
      } else {
        console.error("Failed to add new reward:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding new reward:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        ".MuiPaper-root": {
          borderRadius: "8px",
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2),
        },
      }}
    >
      <DialogTitle
        sx={{ borderBottom: `1px solid ${theme.palette.divider}`, mb: 1 }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Add new Reward season
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          {/* Основная информация */}
          <Box>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
              Basic Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  value={newReward.name || ""}
                  onChange={(e) => handleFieldChange("name", e.target.value)}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Description"
                  value={newReward.description || ""}
                  onChange={(e) =>
                    handleFieldChange("description", e.target.value)
                  }
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>

          <Divider />

          {/* Даты */}
          <Box>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
              Time Frame
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Start Date"
                  type="date"
                  value={newReward.startDate}
                  onChange={(e) =>
                    handleFieldChange("startDate", e.target.value)
                  }
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="End Date"
                  type="date"
                  value={newReward.endDate}
                  onChange={(e) => handleFieldChange("endDate", e.target.value)}
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
              </Grid>
            </Grid>
            <Typography
              variant="body2"
              sx={{ mt: 2, color: theme.palette.text.secondary }}
            >
              Duration: {dayCount} {dayCount === 1 ? "day" : "days"}
            </Typography>
          </Box>

          <Divider />

          {/* Коэффициенты */}
          <Box>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
              Coefficients
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="NFT Level"
                  type="number"
                  value={newReward.coefficients.nftLevel}
                  onChange={(e) =>
                    handleNestedFieldChange(
                      "coefficients",
                      "nftLevel",
                      Number(e.target.value)
                    )
                  }
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="NFT Likes Count"
                  type="number"
                  value={newReward.coefficients.nftLikesCount}
                  onChange={(e) =>
                    handleNestedFieldChange(
                      "coefficients",
                      "nftLikesCount",
                      Number(e.target.value)
                    )
                  }
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="User Boosts Count"
                  type="number"
                  value={newReward.coefficients.userBoostsCount}
                  onChange={(e) =>
                    handleNestedFieldChange(
                      "coefficients",
                      "userBoostsCount",
                      Number(e.target.value)
                    )
                  }
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>

          {/* Статус */}
          <FormControlLabel
            control={
              <Switch
                checked={newReward.isActive}
                onChange={(e) =>
                  handleFieldChange("isActive", e.target.checked)
                }
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  color: newReward.isActive
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                  fontWeight: "bold",
                }}
              >
                {newReward.isActive ? "Active" : "Inactive"}
              </Typography>
            }
            sx={{ my: 2 }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            color: theme.palette.text.primary,
            borderColor: theme.palette.divider,
            marginRight: theme.spacing(1),
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          sx={{ backgroundColor: theme.palette.success.main }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddRewardModal;
