import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  Tooltip,
  Grid,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BASE_URL } from "../../services/baseURL";

const Withdrawal = () => {
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [statusMap, setStatusMap] = useState({});
  const [loadingMap, setLoadingMap] = useState({});

  useEffect(() => {
    const fetchWithdrawalData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/withdrawal-fee`);
        const data = Array.isArray(response.data)
          ? response.data
          : [response.data];
        setWithdrawalData(data);
      } catch (error) {
        console.error("Error fetching withdrawal fee data:", error);
        setWithdrawalData([]);
      }
    };
    fetchWithdrawalData();
  }, []);

  const handleInputChange = (event, id, field) => {
    const updatedData = withdrawalData.map((item) => {
      if (item._id === id) {
        return { ...item, [field]: event.target.value };
      }
      return item;
    });
    setWithdrawalData(updatedData);
  };

  const updateWithdrawalData = async (itemId) => {
    const item = withdrawalData.find((item) => item._id === itemId);
    if (!item) return;

    setLoadingMap((prev) => ({ ...prev, [itemId]: true }));

    try {
      const response = await axios.patch(`${BASE_URL}/withdrawal-fee`, {
        commissionPercentage: item.commissionPercentage,
        withdrawalLimit: item.withdrawalLimit,
      });

      if (response.status === 200) {
        toast.success("Withdrawal settings updated successfully!");
        updateStatus(itemId, "success");
      } else {
        toast.error("Unexpected response from server.");
        updateStatus(itemId, "error");
      }
    } catch (error) {
      console.error("Error updating withdrawal data:", error);
      toast.error("Failed to update withdrawal settings.");
      updateStatus(itemId, "error");
    } finally {
      setLoadingMap((prev) => ({ ...prev, [itemId]: false }));
    }
  };

  const updateStatus = (itemId, status) => {
    setStatusMap((prevStatus) => ({ ...prevStatus, [itemId]: status }));
    setTimeout(() => {
      setStatusMap((prevStatus) => {
        const { [itemId]: _, ...rest } = prevStatus;
        return rest;
      });
    }, 3000);
  };

  return (
    <Box sx={{ p: 4, backgroundColor: "background.default", borderRadius: 2 }}>
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", color: "text.primary", mb: 2 }}
      >
        Withdrawal Settings
      </Typography>
      <Typography variant="body1" sx={{ color: "text.secondary", mb: 4 }}>
        This component allows you to set the commission percentage for Tribe
        withdrawals and the withdrawal limit per transaction.
      </Typography>

      <Grid container spacing={3}>
        {withdrawalData.map((item) => {
          const itemStatus = statusMap[item._id] || "default";
          const isLoading = loadingMap[item._id] || false;

          return (
            <Grid item xs={12} md={6} key={item._id}>
              <Card
                elevation={3}
                sx={{
                  borderRadius: 2,
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                    Withdrawal Settings
                  </Typography>
                  <Tooltip title="Set the commission percentage for this withdrawal">
                    <TextField
                      label="Commission Percentage"
                      value={item.commissionPercentage}
                      onChange={(e) =>
                        handleInputChange(e, item._id, "commissionPercentage")
                      }
                      size="small"
                      type="number"
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  </Tooltip>
                  <Tooltip title="Set the maximum Tribe amount allowed per withdrawal">
                    <TextField
                      label="Withdrawal Limit (Tribe)"
                      value={item.withdrawalLimit}
                      onChange={(e) =>
                        handleInputChange(e, item._id, "withdrawalLimit")
                      }
                      size="small"
                      type="number"
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  </Tooltip>
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    color={itemStatus === "success" ? "success" : "primary"}
                    onClick={() => updateWithdrawalData(item._id)}
                    disabled={itemStatus === "success" || isLoading}
                    startIcon={
                      isLoading && (
                        <CircularProgress size={20} color="inherit" />
                      )
                    }
                  >
                    {itemStatus === "success" ? "Saved" : "Save"}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Withdrawal;
