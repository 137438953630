import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../services/baseURL";
import InventoryIcon from "@mui/icons-material/Inventory";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import VerifiedIcon from "@mui/icons-material/Verified";

export default function CollectionsWidget() {
  const [stats, setStats] = useState({
    totalCollections: 0,
    pendingCollections: 0,
    publishedCollections: 0,
    topLevelCollection: null,
    topLikesCollection: null,
    topPriceCollection: null,
    topTonPriceCollection: null,
    fileTypeStats: {},
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/collection_items`);
        const data = response.data.data;

        const totalCollections = data.length;
        const pendingCollections = data.filter(
          (item) => item.status === "awaiting publication"
        ).length;
        const publishedCollections = data.filter(
          (item) => item.status === "published"
        ).length;
        const topLevelCollection = data.reduce((prev, current) =>
          prev.level > current.level ? prev : current
        );
        const topLikesCollection = data.reduce((prev, current) =>
          prev.likes > current.likes ? prev : current
        );
        const topPriceCollection = data.reduce((prev, current) =>
          prev.price > current.price ? prev : current
        );
        const topTonPriceCollection = data.reduce((prev, current) =>
          prev.tonPrice > current.tonPrice ? prev : current
        );
        const fileTypeStats = data.reduce((acc, item) => {
          const fileType = item?.image?.fileType || "unknown";
          acc[fileType] = (acc[fileType] || 0) + 1;
          return acc;
        }, {});

        setStats({
          totalCollections,
          pendingCollections,
          publishedCollections,
          topLevelCollection,
          topLikesCollection,
          topPriceCollection,
          topTonPriceCollection,
          fileTypeStats,
        });
      } catch (error) {
        console.error("Error fetching collection data:", error);
      }
    };

    fetchData();
  }, []);

  const renderStat = (icon, label, value) => (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        p: 1.5,
        textAlign: "left",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        {icon}
        <Typography
          variant="h6"
          component="div"
          sx={{ ml: 1, fontSize: "1rem" }}
        >
          {label}
        </Typography>
      </Box>
      <Typography
        variant="h4"
        component="div"
        sx={{
          fontWeight: "bold",
        }}
      >
        {value}
      </Typography>
    </Grid>
  );

  const renderTopCollection = (icon, title, collection, attribute) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        p: 2,
        flex: 1,
        textAlign: "left",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        {icon}
        <Typography
          variant="h6"
          sx={{ ml: 1, fontWeight: "bold", fontSize: "1rem" }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        component="img"
        src={collection?.image.previewUrl}
        alt={collection?.name || "Collection Avatar"}
        sx={{
          width: 80,
          height: 80,
          borderRadius: "8px",
          mb: 1,
        }}
      />
      <Box>
        <Typography variant="body1" fontWeight="bold">
          {collection?.name || "N/A"}
        </Typography>
        <Typography variant="body2">
          {attribute}: {collection?.[attribute] || 0}
        </Typography>
      </Box>
    </Box>
  );

  const renderFileTypeStats = (fileTypeStats) => (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        p: 2,
      }}
    >
      {Object.entries(fileTypeStats).map(([fileType, count]) => (
        <Box key={fileType} sx={{ textAlign: "center" }}>
          <Typography variant="body1" fontWeight="bold">
            {fileType}
          </Typography>
          <Typography variant="body2">NFTs: {count}</Typography>
        </Box>
      ))}
    </Box>
  );

  return (
    <Card sx={{ borderRadius: 2, boxShadow: 3, width: "98%" }}>
      <CardContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          NFTs Statistics
        </Typography>

        <Grid container spacing={1} sx={{ mb: 2 }}>
          {renderStat(
            <InventoryIcon fontSize="large" color="primary" />,
            "Total NFT ",
            stats.totalCollections
          )}
          {renderStat(
            <PendingActionsIcon fontSize="large" color="secondary" />,
            "Awaiting publication NFT ",
            stats.pendingCollections
          )}
          {renderStat(
            <VerifiedIcon fontSize="large" color="success" />,
            "Published NFT",
            stats.publishedCollections
          )}
        </Grid>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            {renderTopCollection(
              <TrendingUpIcon color="success" />,
              "Top NFT for Level",
              stats.topLevelCollection,
              "level"
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {renderTopCollection(
              <FavoriteIcon color="error" />,
              "Top NFT for Likes",
              stats.topLikesCollection,
              "likes"
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {renderTopCollection(
              <MonetizationOnIcon color="primary" />,
              "Most expensive nft (Tribe)",
              stats.topPriceCollection,
              "price"
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {renderTopCollection(
              <AttachMoneyIcon color="secondary" />,
              "Most expensive nft (TON )",
              stats.topTonPriceCollection,
              "tonPrice"
            )}
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mb: 2 }}>
          NFTs Type Statistics
        </Typography>
        {renderFileTypeStats(stats.fileTypeStats)}

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          fullWidth
          onClick={() => navigate("/manage-nft/all")}
        >
          Go to Collections Page
        </Button>
      </CardContent>
    </Card>
  );
}
