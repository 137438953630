import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  Grid,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../services/baseURL";

const getStatusStyles = (theme, status) => ({
  backgroundColor:
    status === "awaiting publication"
      ? theme.palette.warning.main
      : theme.palette.success.main,
  color:
    status === "awaiting publication"
      ? theme.palette.getContrastText(theme.palette.warning.main)
      : theme.palette.getContrastText(theme.palette.success.main),
  padding: "4px 8px",
  borderRadius: "4px",
  fontSize: "0.875rem",
  display: "inline-block",
});

export default function ModerationNFTModal({
  open,
  onClose,
  selectedRow,
  placeholderAvatar,
  handleImageOpen,
  onUpdate,
}) {
  const theme = useTheme();
  const [level, setLevel] = useState("");

  useEffect(() => {
    if (selectedRow) {
      setLevel(selectedRow.level || "");
    }
  }, [selectedRow]);

  if (!open || !selectedRow) return null;

  const handleApproveClick = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${BASE_URL}/api/moderation/collection-item/${selectedRow._id}`,
        {
          level,
          status: "approved",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onClose();
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error("Error approving NFT:", error);
    }
  };

  const handleRejectClick = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${BASE_URL}/api/moderation/reject/collection-item/${selectedRow._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onClose();
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error("Error rejecting NFT:", error);
    }
  };

  const fileType = selectedRow.image?.fileType;
  const fileUrl = selectedRow.image?.fileUrl;
  const previewUrl = selectedRow.image?.previewUrl || placeholderAvatar;
  const localPath = selectedRow.image?.localPath;

  const previewElement = (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        variant="caption"
        display="block"
        sx={{ mb: 1, color: theme.palette.text.secondary }}
      >
        Preview
      </Typography>
      <img
        src={previewUrl}
        alt="NFT Preview"
        style={{
          width: "100%",
          maxHeight: "120px",
          objectFit: "cover",
          borderRadius: "8px",
          marginBottom: "8px",
          cursor: "pointer",
        }}
        onClick={() => handleImageOpen(fileUrl || placeholderAvatar)}
      />
    </Box>
  );

  let mediaPlayerElement = null;
  if (fileType === "audio") {
    const audioSrc = `https://media-backend.tribeton.io${localPath || ""}`;
    mediaPlayerElement = (
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Typography
          variant="caption"
          display="block"
          sx={{ mb: 1, color: theme.palette.text.secondary }}
        >
          Audio Player
        </Typography>
        <audio controls style={{ width: "100%" }}>
          <source src={audioSrc} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </Box>
    );
  } else if (fileType === "video") {
    mediaPlayerElement = (
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Typography
          variant="caption"
          display="block"
          sx={{ mb: 1, color: theme.palette.text.secondary }}
        >
          Video Player
        </Typography>
        <iframe
          src={fileUrl}
          title="NFT Video Player"
          style={{ width: "100%", height: "300px", border: "none" }}
          allowFullScreen
        ></iframe>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90vw",
        maxWidth: "800px",
        maxHeight: "90vh",
        bgcolor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        p: 4,
        borderRadius: "8px",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              selectedRow.user?.profile?.photo?.previewUrl || placeholderAvatar
            }
            alt="User Avatar"
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              objectFit: "cover",
              marginRight: "12px",
            }}
          />
          <Box>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
            >
              {selectedRow.name || "No Name"}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.text.secondary }}
            >
              User Type: <strong>{selectedRow.user?.type || "N/A"}</strong>
            </Typography>
          </Box>
        </Box>
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            height: "30px",
            color: theme.palette.text.primary,
            borderColor: theme.palette.divider,
          }}
        >
          Close
        </Button>
      </Box>
      <Divider sx={{ mb: 2, borderColor: theme.palette.divider }} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body2"
            sx={{ mb: 1, color: theme.palette.text.primary }}
          >
            <strong>Category:</strong> {selectedRow.categoryTitle || "N/A"}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: 1, color: theme.palette.text.primary }}
          >
            <strong>Description:</strong>{" "}
            {selectedRow.description || "No description"}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: 1, color: theme.palette.text.primary }}
          >
            <strong>Status:</strong>{" "}
            <span style={getStatusStyles(theme, selectedRow.status)}>
              {selectedRow.status || "Unknown"}
            </span>
          </Typography>

          <Box sx={{ mb: 2, mt: 2 }}>
            <Typography
              variant="body2"
              sx={{
                mb: 1,
                fontWeight: "bold",
                color: theme.palette.text.primary,
              }}
            >
              Level:
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              InputProps={{ inputProps: { min: 0 } }}
              sx={{
                width: "100px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme.palette.divider,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.secondary,
                  },
                },
              }}
            />
          </Box>

          <Typography
            variant="body2"
            sx={{ mb: 1, color: theme.palette.text.primary }}
          >
            <strong>Days Ago:</strong> {selectedRow.daysAgo || "N/A"} days
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          {previewElement}
          {mediaPlayerElement}
        </Grid>
      </Grid>

      <Divider sx={{ my: 2, borderColor: theme.palette.divider }} />
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="contained"
          color="success"
          onClick={handleApproveClick}
        >
          Approve
        </Button>
        <Button variant="contained" color="error" onClick={handleRejectClick}>
          Reject
        </Button>
      </Stack>
    </Box>
  );
}
