import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  Tooltip,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BASE_URL } from "../../services/baseURL";

const Point = () => {
  const [pointData, setPointData] = useState([]);
  const [statusMap, setStatusMap] = useState({});

  useEffect(() => {
    const fetchPointData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/point-service`);
        const data = Array.isArray(response.data)
          ? response.data
          : [response.data];
        setPointData(data);
      } catch (error) {
        console.error("Error fetching point data:", error);
        setPointData([]);
      }
    };
    fetchPointData();
  }, []);

  const handleInputChange = (event, id, field) => {
    const updatedData = pointData.map((item) => {
      if (item.id === id) {
        return { ...item, [field]: event.target.value };
      }
      return item;
    });
    setPointData(updatedData);
  };

  const updatePointData = async (itemId) => {
    const item = pointData.find((item) => item.id === itemId);
    if (!item) return;

    try {
      const response = await axios.patch(
        `${BASE_URL}/point-service/${item.id}`,
        {
          price: item.price,
        }
      );

      if (response.status === 200) {
        toast.success("Point settings updated successfully!");
        updateStatus(itemId, "success");
      } else {
        toast.error("Unexpected response from server.");
        updateStatus(itemId, "error");
      }
    } catch (error) {
      console.error("Error updating point data:", error);
      toast.error("Failed to update point settings.");
      updateStatus(itemId, "error");
    }
  };

  const updateStatus = (itemId, status) => {
    setStatusMap((prevStatus) => ({ ...prevStatus, [itemId]: status }));
    setTimeout(() => {
      setStatusMap((prevStatus) => {
        const { [itemId]: _, ...rest } = prevStatus;
        return rest;
      });
    }, 3000);
  };

  return (
    <Box sx={{ p: 4, backgroundColor: "background.default", borderRadius: 2 }}>
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", color: "text.primary", mb: 2 }}
      >
        Point Settings
      </Typography>
      <Typography variant="body1" sx={{ color: "text.secondary", mb: 4 }}>
        This component allows you to set the point price for specific actions in
        the system.
      </Typography>

      <Grid container spacing={3}>
        {pointData.map((item) => {
          const itemStatus = statusMap[item.id] || "default";

          return (
            <Grid item xs={12} md={6} key={item.id}>
              <Card elevation={3} sx={{ borderRadius: 2 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                    {item.name || "Unnamed Action"}
                  </Typography>
                  <Tooltip title="Set the price for this action">
                    <TextField
                      label="Price"
                      value={item.price}
                      onChange={(e) => handleInputChange(e, item.id, "price")}
                      size="small"
                      type="number"
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  </Tooltip>
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    color={itemStatus === "success" ? "success" : "primary"}
                    onClick={() => updatePointData(item.id)}
                    disabled={itemStatus === "success"}
                  >
                    {itemStatus === "success" ? "Saved" : "Save"}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Point;
