import React, { useState } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import ModerationCollections from "../../Components/ModeratinonNew/ModerationCollections";
import ModerationNFTs from "../../Components/ModeratinonNew/ModerationNft";

const ModerationComponent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div
      style={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <div>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: theme.palette.divider,
            padding: "16px",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* Кнопки */}
            <Stack direction="row" spacing={2}>
              <Button
                variant={activeTab === 0 ? "contained" : "outlined"}
                onClick={() => handleTabChange(0)}
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  transition: "all 0.3s ease",
                  backgroundColor:
                    activeTab === 0
                      ? theme.palette.primary.main
                      : "transparent",
                  color:
                    activeTab === 0
                      ? theme.palette.primary.contrastText
                      : theme.palette.text.primary,
                  borderColor: theme.palette.divider,
                  "&:hover": {
                    backgroundColor:
                      activeTab === 0
                        ? theme.palette.primary.dark
                        : theme.palette.action.hover,
                  },
                }}
              >
                Collections
              </Button>
              <Button
                variant={activeTab === 1 ? "contained" : "outlined"}
                onClick={() => handleTabChange(1)}
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  transition: "all 0.3s ease",
                  backgroundColor:
                    activeTab === 1
                      ? theme.palette.primary.main
                      : "transparent",
                  color:
                    activeTab === 1
                      ? theme.palette.primary.contrastText
                      : theme.palette.text.primary,
                  borderColor: theme.palette.divider,
                  "&:hover": {
                    backgroundColor:
                      activeTab === 1
                        ? theme.palette.primary.dark
                        : theme.palette.action.hover,
                  },
                }}
              >
                NFTs
              </Button>
            </Stack>
            {/* Заголовок */}
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: theme.palette.text.primary,
              }}
            >
              {activeTab === 0 ? "Moderation Collections" : "Moderation NFTs"}
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ padding: "20px" }}>
          {activeTab === 0 && <ModerationCollections />}
          {activeTab === 1 && <ModerationNFTs />}
        </Box>
      </div>
    </div>
  );
};

export default ModerationComponent;
