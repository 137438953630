import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Stack,
  Divider,
  Card,
  CardContent,
  Avatar,
  Chip,
  Link,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../services/baseURL";

const UserDetailsModal = ({ open, onClose, userId }) => {
  const [userData, setUserData] = useState(null);

  // Функция загрузки данных
  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId || !open) return;

      try {
        const response = await axios.get(
          `${BASE_URL}/api/user/profile/${userId}`
        );
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserData();
  }, [userId, open]); // Выполняется только при изменении `userId` или открытии

  if (!open) return null;

  const userProfileLink =
    userData?.type === "creator"
      ? `https://tribeton.io/new-creator/head/${userData._id}`
      : null;

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          color: "text.primary",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        {!userData ? (
          <Typography variant="h6" align="center">
            Loading user data...
          </Typography>
        ) : (
          <>
            {/* Заголовок */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h5" fontWeight="bold">
                User Details
              </Typography>
              {userData.type === "creator" && (
                <Chip
                  label="Creator"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.875rem",
                    bgcolor: "success.main",
                    color: "success.contrastText",
                  }}
                />
              )}
            </Stack>
            <Divider sx={{ mb: 2 }} />

            {/* Информация о профиле */}
            <Card
              sx={{
                mb: 3,
                backgroundColor: "background.default",
                color: "text.primary",
              }}
            >
              <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar
                    src={userData.profile?.photo?.previewUrl || userData.avatar}
                    alt={userData.profile?.telegram || "No Name"}
                    sx={{ width: 150, height: 150 }}
                  />
                  <Box>
                    <Typography variant="h6" fontWeight="bold">
                      {userData.userName || "No Name"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Wallet Address: {userData.walletAddress}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      User Level: {userData.level}
                    </Typography>
                    {userProfileLink && (
                      <Link
                        href={userProfileLink}
                        target="_blank"
                        rel="noopener"
                        underline="hover"
                        sx={{ display: "block", mt: 1, fontWeight: "bold" }}
                      >
                        View Creator Profile
                      </Link>
                    )}
                  </Box>
                </Stack>
              </CardContent>
            </Card>

            {/* Дополнительная информация */}
            <Stack
              direction="row"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Box flex={1}>
                <Typography variant="h6" fontWeight="bold">
                  Statistics
                </Typography>
                <Typography variant="body2">
                  Published Mints: {userData.publishedMints}
                </Typography>
                <Typography variant="body2">
                  Awaiting Mints: {userData.awaitingMints}
                </Typography>
                <Typography variant="body2">Boost: {userData.boost}</Typography>
                <Typography variant="body2">
                  Rewards: {userData.rewards}
                </Typography>
              </Box>

              <Box flex={1}>
                <Typography variant="h6" fontWeight="bold">
                  User Balance
                </Typography>
                {userData.balanceId ? (
                  <>
                    <Typography variant="body2">
                      Total Balance: {userData.balanceId.balance || "N/A"}
                    </Typography>
                    <Typography variant="body2">
                      Available: {userData.balanceId.available || "N/A"}
                    </Typography>
                    <Typography variant="body2">
                      Pending: {userData.balanceId.pending || "N/A"}
                    </Typography>
                    <Typography variant="body2">
                      Locked: {userData.balanceId.locked || "N/A"}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2">
                    No balance information available.
                  </Typography>
                )}
              </Box>
            </Stack>

            {/* Баннер профиля */}
            {userData.profile?.banner?.previewUrl && (
              <Box sx={{ mt: 3, textAlign: "center" }}>
                <Typography variant="h6" fontWeight="bold">
                  Profile Banner
                </Typography>
                <img
                  src={userData.profile.banner.previewUrl}
                  alt="Banner"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "200px",
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            )}

            {/* Кнопка закрытия */}
            <Button
              variant="contained"
              onClick={onClose}
              sx={{ mt: 3, display: "block", marginLeft: "auto" }}
            >
              Close
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default UserDetailsModal;
