import axios from "axios";
import { BASE_URL } from "./baseURL";
const getHeaders = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
};

export const getCollectionItems = async (page) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/collection_items`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching collection items:", error);
    return [];
  }
};

export const getNFTItems = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/nft-items`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching NFT items:", error);
    return [];
  }
};

export const createGallery = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/gallery`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...getHeaders(),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating gallery:", error.message);
    throw error;
  }
};

export const createGalleryEllement = async (element) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/galleryelement`,
      element,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating gallery element:", error.message);
  }
};

export const deleteGallery = async (id) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/api/gallery/delete/${id}`,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting gallery:", error.message);
  }
};
