import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  Grid,
  Stack,
  TextField,
  useTheme,
  Link,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../services/baseURL";

const getStatusStyles = (theme, status) => ({
  backgroundColor:
    status === "awaiting publication"
      ? theme.palette.warning.main
      : theme.palette.success.main,
  color:
    status === "awaiting publication"
      ? theme.palette.getContrastText(theme.palette.warning.main)
      : theme.palette.getContrastText(theme.palette.success.main),
  padding: "4px 8px",
  borderRadius: "4px",
  fontSize: "0.875rem",
  display: "inline-block",
});

export default function ModerationCollectionsModal({
  open,
  onClose,
  selectedRow,
  placeholderAvatar,
  handleImageOpen,
  onUpdate,
}) {
  const theme = useTheme();
  const [level, setLevel] = useState("");

  useEffect(() => {
    if (selectedRow) {
      setLevel(selectedRow.level || "");
    }
  }, [selectedRow]);

  if (!open || !selectedRow) return null;

  const handleApproveClick = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${BASE_URL}/api/moderation/collection/${selectedRow._id}`,
        { level: level, status: "approved" },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      onClose();
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error("Error approving collection:", error);
    }
  };

  const handleRejectClick = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${BASE_URL}/api/moderation/reject/collection/${selectedRow._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      onClose();
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error("Error rejecting collection:", error);
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90vw",
        maxWidth: "800px",
        maxHeight: "90vh",
        bgcolor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        p: 4,
        borderRadius: "8px",
        overflowY: "auto",
        color: theme.palette.text.primary,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              selectedRow.user?.profile?.photo?.previewUrl || placeholderAvatar
            }
            alt="User Avatar"
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              objectFit: "cover",
              marginRight: "12px",
            }}
          />
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {selectedRow.name || "No Name"}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              User Type: <strong>{selectedRow.user?.type || "N/A"}</strong>
            </Typography>
          </Box>
        </Box>
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            height: "30px",
            color: theme.palette.text.primary,
          }}
        >
          Close
        </Button>
      </Box>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Category:</strong> {selectedRow.categoryTitle || "N/A"}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Description:</strong>{" "}
            {selectedRow.description || "No description"}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            <strong>Status:</strong>{" "}
            <span style={getStatusStyles(theme, selectedRow.status)}>
              {selectedRow.status || "Unknown"}
            </span>
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            <strong>Days Ago:</strong> {selectedRow.daysAgo || "N/A"} days
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" sx={{ mb: 1, fontWeight: "bold" }}>
              Level:
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              InputProps={{ inputProps: { min: 0 } }}
              sx={{ width: "100px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Typography variant="caption">Collection Avatar</Typography>
            <img
              src={selectedRow.avatar?.previewUrl || placeholderAvatar}
              alt="Collection Avatar"
              style={{
                width: "100%",
                maxHeight: "120px",
                objectFit: "cover",
                borderRadius: "8px",
                cursor: "pointer",
                boxShadow: theme.shadows[1],
              }}
              onClick={() =>
                handleImageOpen(
                  selectedRow.avatar?.fileUrl || placeholderAvatar
                )
              }
            />
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="caption">Collection Banner</Typography>
            <img
              src={selectedRow.banner?.previewUrl || placeholderAvatar}
              alt="Collection Banner"
              style={{
                width: "100%",
                maxHeight: "120px",
                objectFit: "cover",
                borderRadius: "8px",
                cursor: "pointer",
                boxShadow: theme.shadows[1],
              }}
              onClick={() =>
                handleImageOpen(
                  selectedRow.banner?.fileUrl || placeholderAvatar
                )
              }
            />
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Box sx={{ mb: 3 }}>
        <Typography variant="body2">
          <strong>Telegram:</strong>{" "}
          <Link
            href={selectedRow.telegram || "#"}
            target="_blank"
            rel="noopener"
            color="inherit"
            underline="hover"
          >
            {selectedRow.telegram || "N/A"}
          </Link>
        </Typography>
        <Typography variant="body2">
          <strong>Facebook:</strong>{" "}
          <Link
            href={selectedRow.facebook || "#"}
            target="_blank"
            rel="noopener"
            color="inherit"
            underline="hover"
          >
            {selectedRow.facebook || "N/A"}
          </Link>
        </Typography>
        <Typography variant="body2">
          <strong>Twitter:</strong>{" "}
          <Link
            href={selectedRow.twitter || "#"}
            target="_blank"
            rel="noopener"
            color="inherit"
            underline="hover"
          >
            {selectedRow.twitter || "N/A"}
          </Link>
        </Typography>
        <Typography variant="body2">
          <strong>YouTube:</strong>{" "}
          <Link
            href={selectedRow.youtube || "#"}
            target="_blank"
            rel="noopener"
            color="inherit"
            underline="hover"
          >
            {selectedRow.youtube || "N/A"}
          </Link>
        </Typography>
      </Box>
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="contained"
          color="success"
          onClick={handleApproveClick}
        >
          Approve
        </Button>
        <Button variant="contained" color="error" onClick={handleRejectClick}>
          Reject
        </Button>
      </Stack>
    </Box>
  );
}
