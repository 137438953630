import axios from "axios";

// const BASE_URL = "http://localhost:3001";
// const BASE_URL = "https://dev-api.tribeton.io";
export const BASE_URL = 'https://api.tribeton.io';

export const loginAdmin = async (username, password) => {
  const response = await axios.post(`${BASE_URL}/api/admin/login`, {
    username,
    password,
  });
  return response.data;
};

export const verifyTwoFactor = async (username, twoFactorCode) => {
  const response = await axios.post(`https://dev-api.tribeton.io/api/admin/verify-2fa`, {
    username,
    token: twoFactorCode,
  });
  return response.data;
};

export const setupTwoFactor = async (username) => {
  try {
    const response = await axios.get(
      `https://api.tribeton.io/api/admin/enable-2fa`,
      {
        params: {
          username: "admin", // додаємо username як параметр запиту
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error setting up two-factor authentication:", error);
    throw error;
  }
};
