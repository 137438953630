import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./globalstyles.scss";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
import PublicRoute from "./utils/PublicRoute";
import LoginForm from "./Pages/LoginForm/LoginForm";
import Home from "./Pages/Home/Home";
import Category from "./Pages/Cartegories/Category";
import Gallery from "./Pages/Gallery/Gallery";
import BlogManagement from "./Pages/Blog/BlogNew";
import ChangeLikePrice from "./Pages/ChangePriceLike/ChangePriceLike";
import Seo from "./Pages/Seo/Seo";
import Rewards from "./Pages/Rewards/Rewards";
import UserBoost from "./Pages/UserBoost/UserBoost";
import Withdrawal from "./Pages/Withdrawal/Withdrawal";
import Point from "./Pages/Point/Point";
import AllNftPage from "./Pages/Manage/AllNft/AllNftPage";
import Users from "./Pages/Users/Users";
import ModerationNew from "./Pages/Moderation/ModerationNew";
import Layout from "./Components/Sidebar/Sidebar";
import { ThemeProviderWrapper } from "./ThemeContext";

function App() {
  return (
    <ThemeProviderWrapper>
      <TonConnectUIProvider manifestUrl="https://bafybeiairsnianykvg5gdadfqxqhxzoba7kjme7dxbno7gn3na6g5jovpa.ipfs.w3s.link/tonconnect-manifest.json">
        <BrowserRouter>
          <Routes>
            <Route
              path="login"
              element={<PublicRoute element={<LoginForm />} />}
            />
            <Route path="/" element={<ProtectedRoute element={<Layout />} />}>
              <Route index element={<Navigate to="/home" replace />} />
              <Route path="home" element={<Home />} />
              <Route path="users" element={<Users />} />
              <Route path="manage-nft/all" element={<AllNftPage />} />
              <Route path="moderation-new" element={<ModerationNew />} />
              <Route path="blog" element={<BlogManagement />} />
              <Route path="changePriceLike" element={<ChangeLikePrice />} />
              <Route path="rewards" element={<Rewards />} />
              <Route path="boost-user" element={<UserBoost />} />
              <Route path="withdrawal" element={<Withdrawal />} />
              <Route path="point" element={<Point />} />
              <Route path="category" element={<Category />} />

              {/* <Route path="category" element={<Category />} /> */}
              <Route path="gallery" element={<Gallery />} />
              {/* <Route path="seo" element={<Seo />} />
              <Route path="chagePriceLike" element={<ChangeLikePrice />} />
              <Route path="rewards" element={<Rewards />} />
              <Route path="boost-user" element={<UserBoost />} />
              <Route path="withdrawal" element={<Withdrawal />} />
              <Route path="point" element={<Point />} /> */}
              <Route path="*" element={<Navigate to="/home" replace />} />
            </Route>
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </TonConnectUIProvider>
    </ThemeProviderWrapper>
  );
}

export default App;
