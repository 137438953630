import React from "react";
import {
  Box,
  Button,
  Divider,
  Switch,
  Typography,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function NftModal({
  open,
  handleClose,
  selectedRow,
  handleSwitchChange,
  placeholderAvatar = "https://via.placeholder.com/50",
}) {
  if (!selectedRow) {
    return null; // Возвращаем null, если данных для отображения нет
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          {selectedRow && (
            <>
              {/* Информация о пользователе */}
              <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                <img
                  src={
                    selectedRow.user?.profile?.photo?.previewUrl ||
                    placeholderAvatar
                  }
                  alt="User Avatar"
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: "16px",
                  }}
                />
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold" }}
                    color="text.primary"
                  >
                    {selectedRow.user?.name || "Unknown User"}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    color="text.primary"
                  >
                    User Type: {selectedRow.user?.type || "N/A"}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ mb: 2 }} />

              {/* Основная информация */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                {/* Блок с изображением */}
                <Box sx={{ textAlign: "center", flexBasis: "50%" }}>
                  {selectedRow.image?.fileType === "image" &&
                    selectedRow.image.previewUrl && (
                      <img
                        src={selectedRow.image.previewUrl}
                        alt={selectedRow.name}
                        style={{
                          width: "100%",
                          maxHeight: "300px",
                          borderRadius: "20px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  {selectedRow.image?.fileType === "audio" && (
                    <Box sx={{ textAlign: "center" }}>
                      {selectedRow.image.previewUrl && (
                        <img
                          src={selectedRow.image.previewUrl}
                          alt="Audio Thumbnail"
                          style={{
                            width: "100%",
                            maxHeight: "300px",
                            borderRadius: "20px",
                            objectFit: "cover",
                            marginBottom: "16px",
                          }}
                        />
                      )}
                      <audio controls style={{ width: "100%" }}>
                        <source
                          src={`https://media-backend.tribeton.io${selectedRow.image.localPath}`}
                          type="audio/mpeg"
                        />
                        Your browser does not support the audio element.
                      </audio>
                    </Box>
                  )}
                  {selectedRow.image?.fileType === "video" && (
                    <iframe
                      src={selectedRow.image.fileUrl}
                      title={selectedRow.name}
                      style={{
                        width: "100%",
                        height: "300px",
                        borderRadius: "20px",
                        border: "none",
                      }}
                      allowFullScreen
                    ></iframe>
                  )}
                </Box>

                {/* Текстовая информация */}
                <Box sx={{ flex: 1, ml: 3 }}>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", textAlign: "center" }}
                    color="text.primary"
                  >
                    {selectedRow.name || "No Name"}
                  </Typography>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Typography color="text.primary">
                    <strong>Category:</strong>{" "}
                    {selectedRow.categoryTitle || "N/A"}
                  </Typography>
                  <Typography color="text.primary">
                    <strong>Status:</strong> {selectedRow.status || "Unknown"}
                  </Typography>
                  <Typography color="text.primary">
                    <strong>Price Tribe:</strong> {selectedRow.price || "N/A"}
                  </Typography>
                  <Typography color="text.primary">
                    <strong>Price TON:</strong> {selectedRow.tonPrice || "N/A"}
                  </Typography>
                  <Typography color="text.primary">
                    <strong>Level:</strong> {selectedRow.level || "N/A"}
                  </Typography>
                  <Typography color="text.primary">
                    <strong>Likes:</strong> {selectedRow.likes || "N/A"}
                  </Typography>
                  {selectedRow.isEnabled && selectedRow.image?.fileType && (
                    <Typography sx={{ mt: 2 }} color="text.primary">
                      <strong>View on Platform:</strong>{" "}
                      <a
                        href={`https://tribeton.io/nft-${selectedRow.image.fileType}/${selectedRow.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "#1976d2",
                          textDecoration: "underline",
                        }}
                      >
                        Open NFT
                      </a>
                    </Typography>
                  )}
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color="text.primary"
                  >
                    <strong>Is Enabled:</strong>
                    <Switch
                      checked={selectedRow.isEnabled}
                      onChange={handleSwitchChange}
                    />
                  </Typography>
                </Box>
              </Box>

              <Divider sx={{ mt: 2, mb: 2 }} />
              <Typography color="text.primary">
                <strong>Description:</strong>{" "}
                {selectedRow.description || "No description"}
              </Typography>
              <Button
                variant="contained"
                fullWidth
                onClick={handleClose}
                sx={{ mt: 3 }}
              >
                Close
              </Button>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}
