import React, {useState} from "react";
import { toast } from 'react-toastify';
import { loginAdmin, } from '../../services/authService';
import './LoginForm.scss'
import TwoFaForm from "./TwoFaForm";

const LoginForm = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [show2Fa, setShow2Fa] = useState(false)
    const [token, setToken] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        loginAdmin(username, password)
            .then(data => {
                setToken(data.token)
                setShow2Fa(true)
            })
            .catch(error => {
                console.error('Authentication failed', error);
                toast.error('Authentication failed');
            });
    };



    return (
        <div className="log-background">
            <div className="wrapper">
                <form onSubmit={handleSubmit}>
                    {!show2Fa ?
                        <>
                        <div className="form-title">
                            <h2>Welcome to <span>tribe ton</span></h2>
                            <p>Admin Login to TRIBE TON</p>
                        </div>
                        <div className="form-input">
                            <div className="input-box">
                                <label htmlFor="">Username <span>*</span></label>
                                <input type="text" required value={username}
                                       onChange={(e) => setUsername(e.target.value)}/>
                            </div>
                            <div className="input-box">
                                <label htmlFor="">Password <span>*</span></label>
                                <input type="password" required value={password}
                                       onChange={(e) => setPassword(e.target.value)}/>
                            </div>
                            <div className="remember-forgot">
                                <input id="check" name="check" type="checkbox"/>
                                <label htmlFor="check">Remember me</label>
                            </div>
                            <button type="submit" className="btn">Login</button>
                        </div>
                        </>
                        :
                        <TwoFaForm token={token} username={username} password={password} />
                    }

                </form>
            </div>
        </div>
    );
};

export default LoginForm;
