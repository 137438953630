import React, { useEffect, useState } from "react";
import "./GalleryCardList.scss";
import noImage from "../../Assets/images/no-image.jpg";
import axios from "axios";
import { BASE_URL } from "../../services/baseURL";

const GalleryCardList = ({ items, onItemDoubleClick }) => {
  const handleDoubleClick = (obj) => {
    onItemDoubleClick(obj);
  };

  const [collections, setCollections] = useState([]);

  const fetchCollections = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      const response = await axios.get(
        `${BASE_URL}/api/collection_items?isEnabled=true&status=published`,
        {
          headers: headers,
        }
      );

      const newCollections = response.data.data;
      console.log(newCollections);
      setCollections(newCollections);
    } catch (error) {
      console.error("There was an error fetching the collections!", error);
    }
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  //     const isCategoryMatch =
  //       article.category_id === "667ee42be03e65cd12d5b8c7" ||
  //       article.categoryId === "667ee42be03e65cd12d5b8c7";
  //     const hasTelegramId =
  //       article.collection_id !== undefined || article.collectionId !== undefined;

  //     return isCategoryMatch && hasTelegramId;
  //   };
  //   const fetchPlaybackSources = async () => {
  //     const sources = {};
  //     for (const article of items) {
  //       const imageUrl =
  //         article.image?.previewUrl || article?.image?.fileUrl || noImage;
  //       const playbackId = isVideo(imageUrl);
  //       if (playbackId) {
  //         try {
  //           const playbackSources = await getPlaybackSource(playbackId);
  //           sources[article._id] = getVideoSource(playbackSources);
  //         } catch (error) {
  //           console.error(
  //             `Failed to fetch playback source for NFT ID ${article._id}`,
  //             error
  //           );
  //         }
  //       }
  //     }
  //     setPlaybackSources(sources);
  //   };

  //   useEffect(() => {
  //     fetchPlaybackSources();
  //   }, [items]);

  return (
    <ul
      className="gallery-cards"
      style={{ maxHeight: "580px", overflow: "scroll" }}
    >
      {collections.map((item) => (
        <li
          key={item._id}
          className="gallery-item"
          onDoubleClick={() =>
            handleDoubleClick({
              title: item.name,
              collectionId: item.collection_id,
              owner: item.user,
              elementURL: item.image.fileUrl || item.image.previewUrl,
              urlSite: "/",
              NameGallery: item.name,
              id: item._id,
              categoryId: item.category_id,
              collectionAvatar:
                item.collectionAvatar?.previewUrl ||
                item.collectionAvatar?.fileUrl,
              fileType: item.image.fileType,
              previewUrl: item.image.previewUrl || item.image.fileUrl,
            })
          }
        >
          <img
            src={item?.image?.previewUrl || noImage}
            alt={item.name}
            onError={(e) => {
              e.target.src = noImage;
            }}
          />
        </li>
      ))}
    </ul>
  );
};

export default GalleryCardList;
