import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Modal,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../services/baseURL";
import CategoryIcon from "@mui/icons-material/Category";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import InventoryIcon from "@mui/icons-material/Inventory";

export default function ModerationNFTsWidget() {
  const [stats, setStats] = useState({
    totalNFTs: 0,
    pendingNFTs: 0,
    categories: [],
    latestNFT: null,
  });
  const [openModal, setOpenModal] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const axiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          localStorage.removeItem("token");
          navigate("/login");
        }
        return Promise.reject(error);
      }
    );

    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          "/api/moderation/collection-items"
        );
        const data = response.data.data;

        const totalNFTs = data.length;
        const pendingNFTs = data.filter(
          (item) => item.status === "pending"
        ).length;
        const categories = Array.from(
          new Set(data.map((item) => item.categoryTitle))
        );
        const latestNFT = data[data.length - 1];

        setStats({ totalNFTs, pendingNFTs, categories, latestNFT });
      } catch (error) {
        console.error("Error fetching NFT data:", error);
      }
    };

    fetchData();
  }, [navigate]);

  const handleMediaClick = (media) => {
    setSelectedMedia(media);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedMedia(null);
  };

  const renderStat = (icon, label, value) => (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        p: 1.5,
        textAlign: "left",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        {icon}
        <Typography
          variant="h6"
          component="div"
          sx={{ ml: 1, fontSize: "1rem" }}
        >
          {label}
        </Typography>
      </Box>
      <Typography
        variant="h4"
        component="div"
        sx={{
          fontWeight: "bold",
        }}
      >
        {value}
      </Typography>
    </Grid>
  );

  const renderMediaPreview = () => {
    if (!stats.latestNFT || !stats.latestNFT.image) return null;

    const { fileType, fileUrl, localPath, previewUrl } = stats.latestNFT.image;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
        }}
      >
        {fileType === "image" && (
          <Box
            component="img"
            src={previewUrl}
            alt="NFT Preview"
            sx={{
              width: 80,
              height: 80,
              borderRadius: 2,
              cursor: "pointer",
              boxShadow: 1,
              objectFit: "cover",
            }}
            onClick={() => handleMediaClick({ type: "image", url: previewUrl })}
          />
        )}
        {fileType === "video" && (
          <Box
            sx={{
              width: 120,
              height: 80,
              borderRadius: 2,
              cursor: "pointer",
              overflow: "hidden",
              boxShadow: 1,
            }}
            onClick={() => handleMediaClick({ type: "video", url: fileUrl })}
          >
            <Box
              component="img"
              src={previewUrl}
              alt="Video Preview"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 2,
              }}
            />
          </Box>
        )}
        {fileType === "audio" && (
          <Box
            sx={{
              width: 120,
              height: 80,
              borderRadius: 2,
              cursor: "pointer",
              overflow: "hidden",
              boxShadow: 1,
            }}
            onClick={() =>
              handleMediaClick({
                type: "audio",
                url: `https://media-backend.tribeton.io${localPath}`,
              })
            }
          >
            <Box
              component="img"
              src={previewUrl}
              alt="Audio Preview"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 2,
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Card sx={{ borderRadius: 2, boxShadow: 3, width: "98%" }}>
      <CardContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Moderation NFTs Statistics
        </Typography>

        <Grid container spacing={1}>
          {renderStat(
            <InventoryIcon fontSize="large" color="primary" />,
            "Total NFTs",
            stats.totalNFTs
          )}
          {renderStat(
            <PendingActionsIcon fontSize="large" color="secondary" />,
            "Pending NFTs",
            stats.pendingNFTs
          )}
          {renderStat(
            <CategoryIcon fontSize="large" color="action" />,
            "Categories",
            stats.categories.length
          )}
        </Grid>

        {stats.latestNFT && (
          <Box
            sx={{
              mt: 3,
              p: 2,
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" sx={{ mb: 1 }}>
              Latest NFT
            </Typography>
            <Typography variant="body1">
              <strong>{stats.latestNFT.name}</strong>
            </Typography>
            <Typography variant="body2">
              Category: {stats.latestNFT.categoryTitle}
            </Typography>
            <Typography variant="body2">
              Type: {stats.latestNFT.image.fileType}
            </Typography>
            <Box sx={{ mt: 2 }}>{renderMediaPreview()}</Box>
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          fullWidth
          onClick={() => navigate("/moderation-new")}
        >
          Go to Moderation NFTs Page
        </Button>
      </CardContent>

      {/* Modal for Media Preview */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%",
            maxHeight: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            p: 1,
          }}
        >
          {selectedMedia && (
            <>
              {selectedMedia.type === "image" && (
                <Box
                  component="img"
                  src={selectedMedia.url}
                  alt="Preview"
                  sx={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    borderRadius: 2,
                  }}
                />
              )}
              {selectedMedia.type === "video" && (
                <iframe
                  src={selectedMedia.url}
                  title="Video Preview"
                  style={{
                    width: "500px",
                    height: "500px",
                    border: "none",
                    borderRadius: "8px",
                  }}
                  allowFullScreen
                ></iframe>
              )}
              {selectedMedia.type === "audio" && (
                <audio
                  controls
                  src={selectedMedia.url}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "8px",
                  }}
                ></audio>
              )}
            </>
          )}
        </Box>
      </Modal>
    </Card>
  );
}
