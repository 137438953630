import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Box,
  Divider,
  Switch,
  FormControlLabel,
  Grid,
  useTheme,
} from "@mui/material";
import { BASE_URL } from "../../services/baseURL";

function EditRewardModal({ open, reward, onClose, onSave }) {
  const theme = useTheme();
  const [localReward, setLocalReward] = useState({});
  const [dayCount, setDayCount] = useState(0);
  const [bannerPreview, setBannerPreview] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  useEffect(() => {
    if (reward) {
      setLocalReward({ ...reward });
      if (reward.startDate && reward.endDate) {
        const start = new Date(reward.startDate);
        const end = new Date(reward.endDate);
        const diffInMs = end - start;
        const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        setDayCount(days);

        const today = new Date();
        const isTodayInRange = today >= start && today <= end;
        setIsSaveDisabled(reward.isActive && isTodayInRange);
      } else {
        setDayCount(0);
        setIsSaveDisabled(false);
      }
      if (reward.banner && typeof reward.banner === "string") {
        setBannerPreview(reward.banner);
      }
    }
  }, [reward]);

  const handleFieldChange = (field, value) => {
    setLocalReward((prev) => ({ ...prev, [field]: value }));
  };

  const handleNestedFieldChange = (parentField, field, value) => {
    setLocalReward((prev) => ({
      ...prev,
      [parentField]: {
        ...prev[parentField],
        [field]: value,
      },
    }));
  };

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFieldChange("banner", file);
      setBannerPreview(URL.createObjectURL(file));
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        ...localReward,
        coefficients: {
          nftLevel: localReward.coefficients?.nftLevel || 0,
          nftLikesCount: localReward.coefficients?.nftLikesCount || 0,
          userBoostsCount: localReward.coefficients?.userBoostsCount || 0,
        },
        banner: localReward.banner || null,
      };

      const response = await fetch(
        `${BASE_URL}/rewards_season/${localReward._id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        onSave(localReward);
        onClose();
      } else {
        console.error("Failed to save changes:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        ".MuiPaper-root": {
          borderRadius: "8px",
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2),
        },
      }}
    >
      <DialogTitle
        sx={{ borderBottom: `1px solid ${theme.palette.divider}`, mb: 1 }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Edit Reward Season: {localReward.name || "New Reward"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {isSaveDisabled && (
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.error.main,
              fontWeight: "bold",
              textAlign: "center",
              mb: 2,
            }}
          >
            Editing is disabled because the season is currently active.
          </Typography>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          {/* Основная информация */}
          <Box>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
              Basic information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  value={localReward.name || ""}
                  onChange={(e) => handleFieldChange("name", e.target.value)}
                  fullWidth
                  variant="outlined"
                  disabled={isSaveDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Description"
                  value={localReward.description || ""}
                  onChange={(e) =>
                    handleFieldChange("description", e.target.value)
                  }
                  fullWidth
                  variant="outlined"
                  disabled={isSaveDisabled}
                />
              </Grid>
            </Grid>
          </Box>

          <Divider />

          {/* Даты */}
          <Box>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
              Time frame
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Start date"
                  type="date"
                  value={
                    localReward.startDate
                      ? localReward.startDate.split("T")[0]
                      : ""
                  }
                  onChange={(e) =>
                    handleFieldChange("startDate", e.target.value)
                  }
                  fullWidth
                  variant="outlined"
                  disabled={isSaveDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="End date"
                  type="date"
                  value={
                    localReward.endDate ? localReward.endDate.split("T")[0] : ""
                  }
                  onChange={(e) => handleFieldChange("endDate", e.target.value)}
                  fullWidth
                  variant="outlined"
                  disabled={isSaveDisabled}
                />
              </Grid>
            </Grid>
            <Typography
              variant="body2"
              sx={{ mt: 2, color: theme.palette.text.secondary }}
            >
              Duration: {dayCount} {dayCount === 1 ? "day" : "days"}
            </Typography>
          </Box>

          <Divider />

          {/* Коэффициенты */}
          <Box>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
              Coefficients
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="NFT level"
                  type="number"
                  value={localReward.coefficients?.nftLevel || 0}
                  onChange={(e) =>
                    handleNestedFieldChange(
                      "coefficients",
                      "nftLevel",
                      Number(e.target.value)
                    )
                  }
                  fullWidth
                  variant="outlined"
                  disabled={isSaveDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="NFT Likes"
                  type="number"
                  value={localReward.coefficients?.nftLikesCount || 0}
                  onChange={(e) =>
                    handleNestedFieldChange(
                      "coefficients",
                      "nftLikesCount",
                      Number(e.target.value)
                    )
                  }
                  fullWidth
                  variant="outlined"
                  disabled={isSaveDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="User Boosts"
                  type="number"
                  value={localReward.coefficients?.userBoostsCount || 0}
                  onChange={(e) =>
                    handleNestedFieldChange(
                      "coefficients",
                      "userBoostsCount",
                      Number(e.target.value)
                    )
                  }
                  fullWidth
                  variant="outlined"
                  disabled={isSaveDisabled}
                />
              </Grid>
            </Grid>
          </Box>

          <Divider />

          {/* Баннер */}
          <Box>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
              Banner
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleBannerChange}
                  disabled={isSaveDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {bannerPreview ? (
                  <Box sx={{ textAlign: "center" }}>
                    <img
                      src={bannerPreview}
                      alt="Banner preview"
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Here will be a preview of the banner
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>

          {/* Статус */}
          <FormControlLabel
            control={
              <Switch
                checked={localReward.isActive || false}
                onChange={(e) =>
                  handleFieldChange("isActive", e.target.checked)
                }
                disabled={isSaveDisabled}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  color: localReward.isActive
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                  fontWeight: "bold",
                }}
              >
                {localReward.isActive ? "Active" : "Inactive"}
              </Typography>
            }
            sx={{ my: 2 }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            color: theme.palette.text.primary,
            borderColor: theme.palette.divider,
            marginRight: theme.spacing(1),
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          sx={{ backgroundColor: theme.palette.success.main }}
          disabled={isSaveDisabled}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditRewardModal;
