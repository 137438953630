import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Avatar,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../services/baseURL";
import GroupIcon from "@mui/icons-material/Group";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function UsersWidget() {
  const [stats, setStats] = useState({
    totalUsers: 0,
    creators: 0,
    users: 0,
    topLevelUser: null,
    topBoostUser: null,
    topPublishedUser: null,
    latestUser: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/users`);
        const data = response.data.data;

        const totalUsers = data.length;
        const creators = data.filter((user) => user.type === "creator").length;
        const users = data.filter((user) => user.type === "user").length;

        const topLevelUser = data.reduce((prev, current) =>
          prev.level > current.level ? prev : current
        );
        const topBoostUser = data.reduce((prev, current) =>
          prev.boost > current.boost ? prev : current
        );
        const topPublishedUser = data.reduce((prev, current) =>
          prev.publishedMints > current.publishedMints ? prev : current
        );

        const latestUser = data[data.length - 1];

        setStats({
          totalUsers,
          creators,
          users,
          topLevelUser,
          topBoostUser,
          topPublishedUser,
          latestUser,
        });
      } catch (error) {
        console.error("Error fetching user stats:", error);
      }
    };

    fetchData();
  }, []);

  const renderStat = (
    icon,
    label,
    value,
    user,
    isHighlighted = false,
    showAvatar = false
  ) => (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      sx={{
        display: "flex",
        flexDirection: isHighlighted ? "row" : "column",
        alignItems: isHighlighted ? "center" : "flex-start",
        justifyContent: isHighlighted ? "space-between" : "flex-start",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        p: 1.5,
        textAlign: "left",
        wordBreak: "break-word",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {icon}
        <Typography variant="h6" component="div" sx={{ ml: 1.5 }}>
          {label}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {showAvatar && user?.profile?.photo?.previewUrl ? (
          <Avatar
            src={user.profile.photo.previewUrl}
            sx={{ mr: 1.5, width: 32, height: 32 }}
          />
        ) : showAvatar ? (
          // <AccountCircleIcon sx={{ mr: 1.5, width: 32, height: 32 }} />
          <Avatar src={user.avatar} sx={{ mr: 1.5, width: 32, height: 32 }} />
        ) : null}
        <Typography
          variant={isHighlighted ? "h4" : "body1"}
          component="div"
          sx={{
            fontWeight: isHighlighted ? "bold" : "normal",
            textAlign: "left",
          }}
        >
          {value}
        </Typography>
      </Box>
    </Grid>
  );

  return (
    <Card sx={{ borderRadius: 2, boxShadow: 3, height: "100%", width: "98%" }}>
      <CardContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          User Statistics
        </Typography>

        <Grid container spacing={1}>
          {renderStat(
            <GroupIcon fontSize="large" color="primary" />,
            "Total Users",
            stats.totalUsers,
            null,
            true
          )}
          {renderStat(
            <VerifiedUserIcon fontSize="large" color="secondary" />,
            "Creators",
            stats.creators,
            null,
            true
          )}
          {renderStat(
            <GroupIcon fontSize="large" color="action" />,
            "Users",
            stats.users,
            null,
            true
          )}

          {stats.topLevelUser &&
            renderStat(
              <TrendingUpIcon
                fontSize="large"
                color="success"
                sx={{ mb: 1 }}
              />,
              `Top User (Level: ${stats.topLevelUser.level})`,
              stats.topLevelUser.userName || "N/A",
              stats.topLevelUser,
              false,
              true
            )}

          {stats.topBoostUser &&
            renderStat(
              <RocketLaunchIcon fontSize="large" color="info" sx={{ mb: 1 }} />,
              `Top User by Boost (Boost: ${stats.topBoostUser.boost})`,
              stats.topBoostUser.userName || "N/A",
              stats.topBoostUser,
              false,
              true
            )}

          {stats.topPublishedUser &&
            renderStat(
              <PublishedWithChangesIcon
                fontSize="large"
                color="warning"
                sx={{ mb: 1 }}
              />,
              `Top User by Published (Published: ${stats.topPublishedUser.publishedMints})`,
              stats.topPublishedUser.userName || "N/A",
              stats.topPublishedUser,
              false,
              true
            )}

          {stats.latestUser &&
            renderStat(
              <PersonAddIcon fontSize="large" color="primary" sx={{ mb: 1 }} />,
              "Welcome New User",
              stats.latestUser.userName || "N/A",
              stats.latestUser,
              false,
              true
            )}
        </Grid>

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          fullWidth
          onClick={() => navigate("/users")}
        >
          Go to Users Page
        </Button>
      </CardContent>
    </Card>
  );
}
