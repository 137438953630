import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Modal,
  TextField,
  CircularProgress,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Backdrop,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { BASE_URL } from "../../services/baseURL";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxWidth: "800px",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  color: "text.primary",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
  ],
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "align",
  "link",
  "image",
];

export default function BlogManagement() {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingArticle, setEditingArticle] = useState(null);

  // Добавленные состояния для новых полей
  const [title, setTitle] = useState("");
  const [preText, setPreText] = useState("");
  const [content, setContent] = useState("");
  const [additionally, setAdditionally] = useState("");
  const [link, setLink] = useState("");
  const [author, setAuthor] = useState("");
  const [bannerFile, setBannerFile] = useState(null);
  const [bannerPreview, setBannerPreview] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);

  const getAuthHeaders = () => {
    const token = localStorage.getItem("token");
    return { Authorization: `Bearer ${token}` };
  };

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/api/blogs`, {
        headers: getAuthHeaders(),
      });
      const articlesData = response.data.data.map((article) => ({
        ...article,
        id: article._id,
      }));
      setArticles(articlesData);
      setFilteredArticles(articlesData);
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleOpenCreate = () => {
    setEditingArticle(null);
    setTitle("");
    setPreText(""); // Сброс нового поля
    setContent("");
    setAdditionally(""); // Сброс нового поля
    setLink(""); // Сброс нового поля
    setAuthor(""); // Сброс нового поля
    setBannerFile(null);
    setBannerPreview(null);
    setModalOpen(true);
  };

  const handleOpenEdit = (article) => {
    setEditingArticle(article);
    setTitle(article.title || "");
    setPreText(article.preText || ""); // Заполнение нового поля
    setContent(article.text || "");
    setAdditionally(article.additionally || ""); // Заполнение нового поля
    setLink(article.link || ""); // Заполнение нового поля
    setAuthor(article.author || ""); // Заполнение нового поля
    setBannerPreview(article.image?.previewUrl || null);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setEditingArticle(null);
    setTitle("");
    setPreText("");
    setContent("");
    setAdditionally("");
    setLink("");
    setAuthor("");
    setBannerPreview(null);
    setSubmitting(false);
  };

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setBannerFile(file);
    const reader = new FileReader();
    reader.onload = (evt) => setBannerPreview(evt.target.result);
    reader.readAsDataURL(file);
  };

  const handleDeleteClick = (article) => {
    setArticleToDelete(article);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`${BASE_URL}/api/delete/blog/${articleToDelete.id}`, {
        headers: getAuthHeaders(),
      });
      await fetchBlogs();
      setDeleteDialogOpen(false);
      setArticleToDelete(null);
      setSnackbar({
        open: true,
        message: "Article deleted successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting article:", error);
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Error deleting article",
        severity: "error",
      });
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setArticleToDelete(null);
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const onSaveArticle = async () => {
    if (!title.trim() || !content.trim()) {
      setSnackbar({
        open: true,
        message: "Please fill in both title and content",
        severity: "error",
      });
      return;
    }

    setSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("preText", preText); // Добавлено новое поле
      formData.append("text", content);
      formData.append("additionally", additionally); // Добавлено новое поле
      formData.append("link", link); // Добавлено новое поле

      // Установка значения по умолчанию для author
      const finalAuthor = author.trim() !== "" ? author : "Tribe academy";
      formData.append("author", finalAuthor);

      // Установка текущей даты
      formData.append("date", new Date().toISOString());

      if (bannerFile) formData.append("image", bannerFile);

      if (editingArticle) {
        await axios.put(
          `${BASE_URL}/api/update/blog/${editingArticle.id}`,
          formData,
          {
            headers: {
              ...getAuthHeaders(),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setSnackbar({
          open: true,
          message: "Article updated successfully",
          severity: "success",
        });
      } else {
        await axios.post(`${BASE_URL}/api/blog`, formData, {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "multipart/form-data",
          },
        });
        setSnackbar({
          open: true,
          message: "Article created successfully",
          severity: "success",
        });
      }
      await fetchBlogs();
      handleClose();
    } catch (error) {
      console.error("Error saving article:", error);
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Error saving article",
        severity: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = articles.filter(
      (article) =>
        article.title.toLowerCase().includes(query) ||
        article.id.toLowerCase().includes(query)
    );
    setFilteredArticles(filtered);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "title", headerName: "Title", width: 300 },
    { field: "preText", headerName: "Pre Text", width: 300 }, // Добавлено новое поле в таблицу
    { field: "createdAt", headerName: "Created At", width: 150 },
    { field: "author", headerName: "Author", width: 150 }, // Добавлено новое поле в таблицу
    {
      field: "image",
      headerName: "Image",
      width: 200,
      renderCell: (params) =>
        params.row.image?.previewUrl ? (
          <img
            src={params.row.image.previewUrl}
            alt={params.row.title}
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          />
        ) : (
          "No Image"
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" onClick={() => handleOpenEdit(params.row)}>
            Edit
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleDeleteClick(params.row)}
          >
            Delete
          </Button>
        </Stack>
      ),
    },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "background.default",
        color: "text.primary",
        borderRadius: 2,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          paddingLeft: "20px",
          mb: 3,
        }}
      >
        Blog Management
      </Typography>
      <Divider sx={{ my: 3 }} />
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleOpenCreate}
        sx={{
          ml: 3,
          mb: 3,
        }}
      >
        Create Article
      </Button>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          paddingLeft: "20px",
          mb: 3,
        }}
      >
        <TextField
          label="Search by Title or ID"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          fullWidth
        />
      </Stack>
      {loading ? (
        <Stack justifyContent="center" alignItems="center" sx={{ height: 400 }}>
          <CircularProgress />
        </Stack>
      ) : (
        <Box sx={{ height: 1160, padding: 0, width: "100%", margin: 0 }}>
          <DataGrid
            rows={filteredArticles}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[10, 20, 50]}
            sx={{
              ml: 3,
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "background.default",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "action.hover",
              },
              "& .MuiDataGrid-cell": {
                fontSize: "0.875rem",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
              "& .MuiDataGrid-columnHeader": {
                color: "text.primary",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: "rgba(0, 123, 255, 0.1)",
                "&:hover": {
                  backgroundColor: "rgba(0, 123, 255, 0.15)",
                },
              },
            }}
          />
        </Box>
      )}

      {/* Loading Backdrop */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.modal + 1,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          position: "fixed",
        }}
        open={submitting}
      >
        <CircularProgress color="inherit" size={60} />
        <Typography variant="h6">
          {editingArticle ? "Updating article..." : "Creating article..."}
        </Typography>
      </Backdrop>

      {/* Create/Edit Modal */}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: { backgroundColor: "rgba(0, 0, 0, 0.8)" },
          },
        }}
      >
        <Box sx={modalStyle}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {editingArticle ? "Edit Article" : "Create New Article"}
          </Typography>
          <Stack spacing={2}>
            <TextField
              label="Title"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {/* Новое поле Pre Text */}
            <TextField
              label="Pre Text"
              fullWidth
              value={preText}
              onChange={(e) => setPreText(e.target.value)}
            />
            {/* Новое поле Author */}
            <TextField
              label="Author (default: Tribe academy)"
              fullWidth
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              helperText="If left empty, will default to 'Tribe academy'"
            />
            <ReactQuill
              value={content}
              onChange={setContent}
              modules={quillModules}
              formats={quillFormats}
              style={{ height: "200px", marginBottom: "20px" }}
            />
            {/* Новое поле Additionally */}
            <TextField
              label="Additionally"
              fullWidth
              multiline
              minRows={3}
              value={additionally}
              onChange={(e) => setAdditionally(e.target.value)}
            />
            {/* Новое поле Link */}
            <TextField
              label="Link"
              fullWidth
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 3 }}>
            <Typography>Banner:</Typography>
            <input type="file" onChange={handleBannerChange} />
          </Stack>
          {bannerPreview && (
            <Box
              component="img"
              src={bannerPreview}
              alt="Banner Preview"
              sx={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: 1,
                boxShadow: 1,
                mb: 2,
              }}
            />
          )}
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={onSaveArticle}
              variant="contained"
              disabled={submitting}
            >
              {editingArticle ? "Save Changes" : "Create"}
            </Button>
          </Stack>
        </Box>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this article?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>No</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
